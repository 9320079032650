/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { login, login2fa } from '../redux/AuthActions'
import { LoginStatuses } from '../enums/LoginStatuses'
import { RootState } from '../../../../setup'
import * as authRedux from '../redux/AuthRedux'
import { Timer } from '../../onlinePayment/components/Timer'
import { getLoginResponseErrorMessage } from '../functions/loginFunctions'

const login2faSchema = Yup.object().shape({
  code: Yup.string()
    .required('Code is required')
})

const initialValues = {
  code: ''
}

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

interface LocationState {
  username: string,
  password: string,
}

const Login2FA: FC<PropsFromRedux> = ({ auth }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [forRecoveryCode, setForRecoveryCode] = useState(false)
  const [stopTimer, setStopTimer] = useState(false)

  useEffect(() => {
    let locationState = location.state as LocationState

    if (!locationState || !(locationState.username != '' && locationState.password != ''))
      navigate('/Login');
    else {
      setUsername(locationState.username);
      setPassword(locationState.password);
    }

    window.history.replaceState({}, document.title)
  }, [location]);

  const formik = useFormik({
    initialValues,
    validationSchema: login2faSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        let stopLoading = true;

        try {
          let loginResponse = await login2fa(username, password, values.code, forRecoveryCode);

          if (!loginResponse.isSuccess) {
            setStatus(loginResponse.errorsDisplay);
          } else {
            let data = loginResponse.data;

            let errorMessage = getLoginResponseErrorMessage(data?.loginStatus!);

            if (errorMessage)
              setStatus(errorMessage);

            switch (data?.loginStatus) {
              case LoginStatuses.Success:
                setStopTimer(true);
                dispatch(authRedux.actions.login(data?.accessToken!, data?.refreshToken!, data?.userId!));
                stopLoading = false;
                break;
            }
          }
        } catch (e) {
          setStatus("A problem was encountered while processing your request. Please try again later.");
          stopLoading = true;
        }

        if (stopLoading) {
          setSubmitting(false);
          setLoading(false);
        }
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        {!forRecoveryCode &&
          <h1 className='text-dark mb-3'>Two-factor authentication</h1>
        }
        {forRecoveryCode &&
          <h1 className='text-dark mb-3'>Recovery code verification</h1>
        }
      </div>
      {/* begin::Heading */}

      {!forRecoveryCode &&
        <p>Your login is protected with an authenticator app. Enter your authenticator code below</p>
      }

      {forRecoveryCode &&
        <p>You have requested to log in with a recovery code. This login will not be remembered until you provide
          an authenticator app code at log in or disable 2FA and log in again.</p>
      }

      {formik.status &&
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      }

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
        <input
          placeholder='Code'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.code && formik.errors.code },
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          name='code'
          autoComplete='off'
          disabled={loading}
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div>
        <Timer precedingText={"Login session will expire in"}
          startingMins={1}
          stopTimer={stopTimer}
          onFinish={() => {
            navigate('/Login');
          }} />
      </div>

      <br />

      <div>
        {!forRecoveryCode &&
          <a
            className='link-primary fs-6 fw-bolder'
            style={{ marginLeft: '5px', cursor: "pointer" }}
            onClick={() => { setForRecoveryCode(true); }}>
            log in with a recovery code
          </a>
        }
        {forRecoveryCode &&
          <a
            className='link-primary fs-6 fw-bolder'
            style={{ marginLeft: '5px', cursor: "pointer" }}
            onClick={() => { setForRecoveryCode(false); }}>
            log in with authenticator code
          </a>
        }
      </div>

    </form>
  )
}

export default connector(Login2FA);