/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as subscriptionRenewalClientRedux from '../redux/SubscriptionRenewalClientRedux'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Alert, Button, Card, CardGroup, Col, Nav, Row } from 'react-bootstrap'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { RootState } from '../../../setup/redux/RootReducer'
import { getClientRenewalDetails, pageAccessed, saveClientRenewal } from '../redux/SubscriptionRenewalClientActions'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import moment from 'moment'
import { InsuranceTypes } from '../../subscription/enums/InsuranceTypes'
import * as Yup from 'yup'
import { PendingSubscriptionClientConfirmWriteModel } from '../models/PendingSubscriptionClientConfirmWriteModel'
import { Form, Formik } from 'formik'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { ButtonSpinner } from '../../../@Common/UIComponents/ButtonSpinner'
import { FormGroupSelect } from '../../../@Common/FormComponents/FormGroupSelect'
import { nameOf } from '../../../@Common/Helpers/Utilities'
import { ConfirmationModal } from '../../../@Common/UIComponents/ConfirmationModal'
import { format } from 'numerable';

const mapState = (state: RootState) => ({ subscriptionRenewalClient: state.subscriptionRenewalClient })
const connector = connect(mapState, subscriptionRenewalClientRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const renewalSchema: Yup.SchemaOf<PendingSubscriptionClientConfirmWriteModel> = Yup.object().shape({
    renewalId: Yup.string()
        .label("Renewal Id")
        .required(),
    installment: Yup.number()
        .label("Installment")
        .required('Installment is required')
        .min(1)
});

const ClientRenewal: FC<PropsFromRedux> = ({ subscriptionRenewalClient }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isSavedSuccessful, setIsSavedSuccessful] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const renewalId = searchParams.get("renewalId");
    const { loading, errorMessages, renewalDetails } = subscriptionRenewalClient;

    const formikRef = useRef(null);
    const getFormikForm = () => (formikRef.current as any);

    const labelCol = 3;
    const THIRD_PARTY_ONLY: string = 'THIRD PARTY ONLY';

    useEffect(() => {
        async function getRenewalDetails() {
            dispatch(subscriptionRenewalClientRedux.actions.requestClientRenewalDetails(renewalId!));
        }

        if (renewalId == null || renewalId == '') {
            let path = generatePath("/error/500", {});
            return navigate(path);
        } else
            getRenewalDetails();
    }, [])

    useEffect(() => {
        async function logPageAccessed() {
            await pageAccessed(renewalId!);
        }

        if (renewalDetails)
            logPageAccessed();
    }, [renewalDetails])

    const displayRow = (label: string, value: any) =>
        <Row>
            <Col xs={labelCol}>
                <b>{label}</b>
            </Col>
            <Col>
                {value}
            </Col>
        </Row>;

    const displayRenewalDetails = () =>
        <>
            {renewalDetails &&
                <Cards
                    border='dark'
                    header={<h2>Details</h2>}
                >
                    {displayRow("Insurance Type", renewalDetails.insuranceTypeStr)}
                    {displayRow("Policy Number", renewalDetails.policyNumber)}
                    {displayRow("Full Name", renewalDetails.name + " " + renewalDetails.surname)}
                    {displayRow("Policy Start Date", moment(renewalDetails.policyStartDate).format("DD/MM/YYYY"))}
                    {displayRow("Policy End Date", moment(renewalDetails.policyEndDate).format("DD/MM/YYYY"))}
                    {displayRow("Sum Insured (updated for upcoming renewal)", "€" + renewalDetails.sumInsured.toFixed(2))}
                    <br />
                    {renewalDetails.insuranceType == InsuranceTypes.MotorInsurance && displayMotorInsuranceDetails()}
                    {renewalDetails.insuranceType == InsuranceTypes.HomeInsurance && displayHomeInsuranceDetails()}
                </Cards>
            }
        </>;

    const displayMotorInsuranceDetails = () => {
        let motorDetails = renewalDetails!.motorDetails!;

        return <>
            {displayRow("Product", motorDetails.product)}
            {displayRow("Number Plate", motorDetails.numberPlate)}
            {displayRow("Manufacturer", motorDetails.manufacturer)}
            {displayRow("Model", motorDetails.model)}
            {displayRow("Protected NCD", (motorDetails.protectedNCD != null) ? (motorDetails.protectedNCD ? "Yes" : "No") : "None")}
            {displayRow("Type of Cover", motorDetails.typeOfCover)}
            {displayRow("Is Up For VRT", <b>{motorDetails.isUpToVRT ? "Yes" : "No"}</b>)}
            <br />
            {displayRow("TM Fines", "€" + format(motorDetails.tmFines, '0,0.00'))}
            {displayRow("TM Fees (CVA)", "€" + format(motorDetails.tmFees, '0,0.00'))}
            {displayRow("TM License Fees", "€" + format(motorDetails.tmLicenseFees, '0,0.00'))}
            {displayRow("Premium Due", "€" + format(motorDetails.renewalPolicyRecordsPremiumDue, '0,0.00'))}
            {displayRow("Stamp Duty Due", "€" + format(motorDetails.policyRecordsStampDutyDue, '0,0.00'))}
            {displayRow("Policy Fees Due", "€" + format(motorDetails.policyRecordsPolicyFeesDue, '0,0.00'))}
            {displayRow("Total for Insurance and License Fees", "€" + format((renewalDetails!.costOfInsurance + renewalDetails!.costOfRoadTaxAndFines), '0,0.00'))}
        </>;
    }

    const displayHomeInsuranceDetails = () => {
        let homeDetails = renewalDetails!.homeDetails!;

        return <>
            {displayRow("Policy Type", homeDetails.policyType)}
            {displayRow("Premium", "€" + format(homeDetails.premium, '0,0.00'))}
            {displayRow("Duty", "€" + format(homeDetails.duty, '0,0.00'))}
            {displayRow("Fee", "€" + format(homeDetails.fee, '0,0.00'))}
            {displayRow("Total Due", "€" + format((renewalDetails?.costOfInsurance! + renewalDetails?.administrativeFee!), '0,0.00'))}
            {displayRow("Risk Address", homeDetails.riskAddress1 + ' ' + homeDetails.riskAddress2 + ' ' + homeDetails.riskAddress3)}
            {displayRow("Buildings Sum Insured", "€" + format(homeDetails.buildingsSumInsured, '0,0.00'))}
            {displayRow("Contents Sum Insured", "€" + format(homeDetails.contentsSumInsured, '0,0.00'))}
            {displayRow("Personal Belongings", "€" + format(homeDetails.personalBelongings, '0,0.00'))}
        </>;
    }

    const getInstallments = () => {
        if (renewalDetails?.insuranceType == InsuranceTypes.MotorInsurance && renewalDetails.motorDetails?.typeOfCover.toUpperCase() == THIRD_PARTY_ONLY)
            return [
                { value: 1, label: 'Proceed to immediate payment' }
            ];
        else
            return [
                { value: 1, label: 'Proceed to immediate payment' },
                { value: 2, label: 'Payment over 2 months' },
                { value: 3, label: 'Payment over 3 months' }
            ];
    }

    const displayRenewalInformationBox = () =>
        <>
            {renewalDetails &&
                <Cards
                    border='dark'
                >
                    <p>
                        “In the event of any changes in the risk that could influence insurers or the cover you enjoy, please inform us in good time prior to renewal. Should you require any guidance to understand if the change in risk is relevant to insurers, we request you to contact us without delay.”
                    </p>
                    <p>
                        You may access the Insurance Product Information Document through the download section from the following link:&nbsp;
                        {renewalDetails.insuranceType == InsuranceTypes.HomeInsurance &&
                            <a href="https://www.england.com.mt/portfolio/home-insurance/" target="_blank">https://www.england.com.mt/portfolio/home-insurance/</a>
                        }
                        {renewalDetails.insuranceType == InsuranceTypes.MotorInsurance &&
                            <a href="https://www.england.com.mt/portfolio/motor-insurance/" target="_blank">https://www.england.com.mt/portfolio/motor-insurance/</a>
                        }
                        .
                    </p>
                    <p>
                        In the event you wish to update the above value please contact our offices or your intermediary.
                    </p>
                    <p>
                        You may also pay at {renewalDetails?.intermediaryGroup} or call on {renewalDetails?.tiiContactDetails}.
                    </p>
                    <p>
                        Or by credit card through the options hereunder.
                    </p>
                </Cards>
            }
        </>;

    const displayHomeInsuranceRenewalInformationBox = () => {
        let homeDetails = renewalDetails!.homeDetails!;

        return <>

        </>;
    }

    const onSave = () => {
        if (getFormikForm().isValid && getFormikForm().dirty)
            setShowConfirmModal(true);
    }

    const onSaveConfirm = () => {
        getFormikForm().submitForm();
    }

    const displayInstallmentChosen = (installemntsChosen: number) => {
        let installmentDisplay: string[] = [];
        let monthlyAmount = +((renewalDetails!.costOfInsurance / installemntsChosen).toFixed(2));

        for (let installment = 1; installment <= installemntsChosen; installment++) {
            let display = "";

            if (installment == 1 && installemntsChosen == 1)
                display = "Full Payment"
            else
                display = `Payment ${installment}`;

            display += ` - €${monthlyAmount.toFixed(2)} Insurance`;

            if (installment == 1) {
                if (renewalDetails!.insuranceType == InsuranceTypes.MotorInsurance)
                    display += ` + €${renewalDetails!.costOfRoadTaxAndFines.toFixed(2)} License`;

                if (installemntsChosen > 1)
                    display += ` + €5 Fee`;
            }

            installmentDisplay.push(display);
        }

        return (<ul>
            {installmentDisplay.map(display =>
                <li>
                    {display}
                </li>
            )}
        </ul>);
    }

    const displayForm = () =>
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    renewalId: renewalId,
                    installment: 0
                } as PendingSubscriptionClientConfirmWriteModel}
                validationSchema={renewalSchema}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={(values: PendingSubscriptionClientConfirmWriteModel, { setSubmitting, setStatus, resetForm }) => {
                    setIsSaving(true)
                    setTimeout(async () => {
                        try {
                            var saveResponse = await saveClientRenewal(values);

                            if (!saveResponse.isSuccess) {
                                showToastrErrors(saveResponse.errors);
                            } else {
                                setShowConfirmModal(false);
                                setIsSavedSuccessful(true);
                            }
                        } catch (e) {
                            showToastrError('An unexpected error has occured');
                        }

                        setSubmitting(false);
                        setIsSaving(false);
                    }, 1000);
                }}
            >
                {(props) => (
                    <>
                        <Form noValidate onSubmit={props.handleSubmit}>
                            <Cards
                                border='dark'
                                footer={
                                    <>
                                        <div className='d-flex justify-content-start'>
                                            <ButtonSpinner
                                                onClick={onSave}
                                                variant="primary"
                                                text='Submit'
                                                saving={isSaving}
                                                className='m-1'
                                                disabled={!props.dirty}
                                            />
                                        </div>
                                    </>
                                }
                            >
                                <FormGroupSelect
                                    className="mb-3 col-12"
                                    controlId="formInstallment"
                                    label="Please choose payment method from Drop down"
                                    name={`${nameOf<PendingSubscriptionClientConfirmWriteModel>("installment")}`}
                                    onBlur={props.setFieldTouched}
                                    onChange={props.setFieldValue}
                                    value={props.values.installment}
                                    touched={props.touched.installment}
                                    isInvalidError={props.errors.installment}
                                    options={getInstallments()}
                                />
                                {props.values.installment > 0 && displayInstallmentChosen(props.values.installment)}
                            </Cards>
                        </Form>
                    </>
                )}
            </Formik>
        </>;

    return (
        <>
            <h1>Subscription Renewal</h1>

            <ErrorBoundary>
                <Loader loading={loading}>
                    <>
                        {errorMessages &&
                            <Alert variant="danger">
                                <p>
                                    <ul>
                                        {errorMessages.map(e => <li>{e}</li>)}
                                    </ul>
                                </p>
                            </Alert>
                        }
                        {renewalDetails && !isSavedSuccessful &&
                            <>
                                {displayRenewalDetails()}
                                {displayRenewalInformationBox()}
                                {displayForm()}
                            </>
                        }
                        {isSavedSuccessful &&
                            <Alert variant="success">
                                <p>
                                    Renewal has been successfully saved. Kindly check your email to proceed with payment.
                                </p>
                            </Alert>
                        }
                    </>
                </Loader>
                <ConfirmationModal
                    title='Proceed Renewal'
                    question={<>
                        Are you sure you want to proceed with the selected details?
                    </>}
                    show={showConfirmModal}
                    saving={isSaving}
                    onConfirm={onSaveConfirm}
                    onClose={() => {
                        setShowConfirmModal(false);
                    }}
                />
            </ErrorBoundary>
        </>
    );
}

export default connector(ClientRenewal)