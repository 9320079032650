export enum Icons {
    Plus = "fas fa-plus",
    Trash = "fas fa-trash",
    User = "fas fa-user",
    Times = "fas fa-times",
    Check = "fas fa-check",
    Envelope = "fas fa-envelope",
    ExclamationTriangle = "fas fa-exclamation-triangle",
    Lock = "fas fa-lock",
    PeopleArrows = "fas fa-people-arrows",
    UserPlus = "fas fa-user-plus",
    AngleUp = "fas fa-angle-up",
    AngleDown = "fas fa-angle-down",
    AngleDoubleLeft = "fas fa-angle-double-left",
    AngleDoubleRight = "fas fa-angle-double-right",
    AngleRight = "fas fa-angle-right",
    AngleLeft = "fas fa-angle-left",
    Spinner = "fas fa-spinner",
    Expand = "fas fa-expand",
    Edit = "fas fa-edit",
    FileDownload = "fas fa-file-download",
    PaperPlane = "fas fa-paper-plane",
    File = "fas fa-file",
    FilePDF = "fas fa-file-pdf",
    Bell = "fas fa-bell",
    Google = "fab fa-google",
    Facebook = "fab fa-facebook",
    InfoCircle = "fas fa-info-circle"
}