/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getAvailableAuthTypes, login } from '../../redux/AuthActions'
import { LoginStatuses } from '../../enums/LoginStatuses'
import { RootState } from '../../../../../setup'
import * as authRedux from '../../redux/AuthRedux'
import { AuthTypes } from '../../enums/AuthTypes'
import { getLoginResponseErrorMessage } from '../../functions/loginFunctions'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required'),
  password: Yup.string()
    .required('Password is required')
})

const initialValues = {
  username: (process.env.NODE_ENV !== 'production') ? 'god@icon' : '',
  password: (process.env.NODE_ENV !== 'production') ? '1qaz' : ''
}

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const UP: FC<PropsFromRedux> = ({ auth }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  let { loggingIn } = auth;

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      dispatch(authRedux.actions.loggingInStarted())

      setTimeout(async () => {
        let stopLoading = true;

        try {
          let loginResponse = await login(values.username, values.password);

          if (!loginResponse.isSuccess) {
            setStatus(loginResponse.errorsDisplay);
          } else {
            let data = loginResponse.data;

            let errorMessage = getLoginResponseErrorMessage(data?.loginStatus!);

            if (errorMessage)
              setStatus(errorMessage);

            switch (data?.loginStatus) {
              case LoginStatuses.Success:
                dispatch(authRedux.actions.login(data?.accessToken!, data?.refreshToken!, data?.userId!));
                stopLoading = false;
                break;

              case LoginStatuses.RequiresTwoFactor:
                navigate('Login2FA', { state: { username: values.username, password: values.password } });
                break;

              case LoginStatuses.ExpiredPassword:
                navigate('expired-change-password', { state: { username: values.username } });
                break;
            }
          }
        } catch (e) {
          setStatus("A problem was encountered while processing your request. Please try again later.");
          stopLoading = true;
        }

        if (stopLoading) {
          setSubmitting(false);
          setLoading(false);
          dispatch(authRedux.actions.loggingInFinished())
        }
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
      </div>
      {/* end::Heading */}

      {/* begin::Status */}
      {formik.status &&
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      }
      {/* end::Status */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          name='username'
          autoComplete='off'
          disabled={loading}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          disabled={loading}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || loggingIn}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

export default connector(UP);