import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { FileModel } from '../../../@Common/Models/FileModel';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { PendingSubscriptionDashboardReadModel } from '../models/PendingSubscriptionDashboardReadModel';
import { SubscriptionDashboardSearchModel } from '../models/SubscriptionDashboardSearchModel';
import { SubscriptionRenewalDetailsWriteDto } from '../models/SubscriptionRenewalDetailsWriteDto';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/subscriptionRenewalDashboard";

export const GET_RENEWAL_SUBSCRIPTIONS_URL = `${API_URL}/GetRenewalSubscriptions`;
export const GET_RENEWAL_SUBSCRIPTION_URL = `${API_URL}/GetRenewalSubscription`;
export const EDIT_SUBSCRIPTION_RENEWAL_DETAILS_URL = `${API_URL}/EditSubscriptionRenewalDetails`;
export const RESEND_PENDING_SUBSCRIPTION_EMAIL_URL = `${API_URL}/ReSendPendingSubscriptionEmail`;
export const GENERATE_MULTIPLE_PDF_URL = `${API_URL}/GenerateMultiplePDF`;
export const GENERATE_SINGLE_PDF_URL = `${API_URL}/GenerateSinglePDF`;

export const getRenewalSubscriptions = async (filter: GridFilterDto, searchData: SubscriptionDashboardSearchModel) => {
  let response = await postToServerAPI<PaginatedServiceResponse<PendingSubscriptionDashboardReadModel>>(GET_RENEWAL_SUBSCRIPTIONS_URL, { filter, searchData });
  return response;
}

export const getPendingSubscriptionDetails = async (id: number) => {
  let response = await getFromServerAPI<ServiceResponse<PendingSubscriptionDashboardReadModel>>(GET_RENEWAL_SUBSCRIPTION_URL, { id });
  return response;
}

export const editPendingSubscriptionDetails = async (model: SubscriptionRenewalDetailsWriteDto) => {
  let response = await postToServerAPI<ServiceResponse>(EDIT_SUBSCRIPTION_RENEWAL_DETAILS_URL, model);
  return response;
}

export const resendPendingSubscriptionEmail = async (pendingSubscriptionId: number, isReminder: boolean) => {
  let response = await postToServerAPI<ServiceResponse>(RESEND_PENDING_SUBSCRIPTION_EMAIL_URL, { pendingSubscriptionId, isReminder });
  return response;
}

export const generateMultiplePDF = async (reminderOnly: boolean, searchData: SubscriptionDashboardSearchModel) => {
  let response = await postToServerAPI<ServiceResponse<FileModel>>(GENERATE_MULTIPLE_PDF_URL, { reminderOnly, searchData });
  return response;
}

export const generateSinglePDF = async (pendingSubscriptionId: number) => {
  let response = await postToServerAPI<ServiceResponse<FileModel>>(GENERATE_SINGLE_PDF_URL, { pendingSubscriptionId });
  return response;
}