import { LoginStatuses } from "../enums/LoginStatuses";

export const getLoginResponseErrorMessage = (loginStatus: LoginStatuses) => {

    switch (loginStatus) {
        case LoginStatuses.UnknownError:
            return "There was a problem while logging you into your account. Please try again later.";

        case LoginStatuses.InvalidUsernamePassword:
            return "Invalid username and password.";

        case LoginStatuses.LockedOut:
            return "Your account has been locked. Kindly contact the support team to regain access";

        case LoginStatuses.InvalidToken:
            return "Invalid Token.";

        case LoginStatuses.Deactivated:
            return "Your account has been deactivated. Kindly contact the support team to regain access.";

        default:
            return;
    }
}