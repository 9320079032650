import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { SubscriptionRenewalProcessedResultModel } from "../models/SubscriptionRenewalProcessedResultModel";

export const actionTypes = {
    ClearResults: '[Clear Results]',
    ProcessedResultsLoaded: '[Loaded Processed Results] API',
}

export interface ISubscriptionRenewalState {
    loading: boolean,
    processResult?: SubscriptionRenewalProcessedResultModel
}

const initialSubscriptionRenewalState: ISubscriptionRenewalState = {
    loading: false
}

export const reducer = (state: ISubscriptionRenewalState = initialSubscriptionRenewalState, action: ActionWithPayload<ISubscriptionRenewalState>): ISubscriptionRenewalState => {
    switch (action.type) {
        case actionTypes.ClearResults: {
            return { 
                ...state,
                processResult: undefined
            };
        }
        case actionTypes.ProcessedResultsLoaded: {
            return { 
                ...state,
                processResult: action.payload?.processResult!
            };
        }

        default:
            return state
    }
}

export const actions = {
    clearResults: () => ({ type: actionTypes.ClearResults }),
    fulfillProcessResult: (processResult: SubscriptionRenewalProcessedResultModel) => ({ type: actionTypes.ProcessedResultsLoaded, payload: { processResult } }),
}

export function* saga() {
    
}