/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getAvailableAuthTypes, getFacebookAppId, getGoogleClientId, login, login_Facebook, login_Google } from '../../redux/AuthActions'
import { LoginStatuses } from '../../enums/LoginStatuses'
import { RootState } from '../../../../../setup'
import * as authRedux from '../../redux/AuthRedux'
import { AuthTypes } from '../../enums/AuthTypes'
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { showToastrError } from '../../../../../@Common/Helpers/Toastr'
import { Button } from 'react-bootstrap'
import GoogleButton from './Google/GoogleButton'
import FacebookLogin, { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import { ButtonSpinner } from '../../../../../@Common/UIComponents/ButtonSpinner'
import { Icons } from '../../../../../@Common/Helpers/Icons'
import { UserLoginFacebookModel } from '../../models/UserLoginFacebookModel'
import { getLoginResponseErrorMessage } from '../../functions/loginFunctions'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const FB: FC<PropsFromRedux> = ({ auth }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [appId, setAppId] = useState("")

  let { loggingIn } = auth;

  useEffect(() => {
    getFacebookId();
  }, []);

  const getFacebookId = async () => {
    try {
      let appIdResponse = await getFacebookAppId();

      if (appIdResponse.isSuccess)
        setAppId(appIdResponse.data!);
      else
        showToastrError("A problem was encountered while setting up facebook login mechanisms. Please try again later.");
    } catch (error) {
      showToastrError("A problem was encountered while setting up facebook login mechanisms. Please try again later.");
    }
  }

  const onLogin = async (accessToken: string, userId: string) => {
    let stopLoading = true;
    setLoading(true)
    dispatch(authRedux.actions.loggingInStarted())

    let userLoginFacebookModel = {
      accessToken: accessToken,
      userId: userId
    } as UserLoginFacebookModel;

    let loginResponse = await login_Facebook(userLoginFacebookModel);

    if (!loginResponse.isSuccess) {
      showToastrError(loginResponse.errorsDisplay);
    } else {
      let data = loginResponse.data;

      let errorMessage = getLoginResponseErrorMessage(data?.loginStatus!);

      if (errorMessage)
        showToastrError(errorMessage);

      switch (data?.loginStatus) {
        case LoginStatuses.Success:
          dispatch(authRedux.actions.login(data?.accessToken!, data?.refreshToken!, data?.userId!));
          stopLoading = false;
          break;
      }
    }

    if (stopLoading) {
      setLoading(false);
      dispatch(authRedux.actions.loggingInFinished())
    }
  };

  return (
    <>
      {appId &&
        <FacebookLogin
          appId={appId}
          onSuccess={(response) => {
            onLogin(response.accessToken, response.userID)
          }}
          onFail={(error) => {
            showToastrError("There was a problem to logging in with your facebook account. Please try again later.");
          }}
          render={({ onClick, logout }) => (
            <ButtonSpinner
              className='btn btn-lg btn-secondary w-100 mb-5'
              onClick={onClick}
              variant="primary"
              saving={loading}
              disabled={loggingIn}
            >
              <i className={Icons.Facebook}></i> Continue with Facebook
            </ButtonSpinner>
          )}
        />
      }
    </>
  )
}

export default connector(FB);