import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { OptionModel } from '../../../@Common/Models/OptionModel';
import { UsersRoles } from '../../core/people/models/UsersRoles';
import { IDTypes } from '../enums/IDTypes';
import { InsuranceTypes } from '../enums/InsuranceTypes';
import { SubscriptionWriteModel } from '../models/SubscriptionModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/subscription";

const CREATE_SUBSCRIPTION = API_URL + "/CreateSubscription";
const GET_USER_MAX_INSTALLMENTS_URL = API_URL + "/GetUserMaxInstallments";

export const saveNewSubscription = async (subscription: SubscriptionWriteModel) => {
    let response = await postToServerAPI<ServiceResponse>(CREATE_SUBSCRIPTION, subscription);
    return response;
}

export const getUserMaxInstallments = async () => {
    let response = await getFromServerAPI<ServiceResponse<number>>(GET_USER_MAX_INSTALLMENTS_URL, {});
    return response;
}

export const getIDTypes = () => {
    return [
        { value: IDTypes.Passport, label: 'Passport' },
        { value: IDTypes.IDCard, label: 'ID Card' },
        { value: IDTypes.CRN, label: 'CRN' }
    ];
}

export const getInsuranceTypes = () => {
    return [
        { value: InsuranceTypes.HomeInsurance, label: 'Home Insurance' },
        { value: InsuranceTypes.MotorInsurance, label: 'Motor Insurance' },
        { value: InsuranceTypes.MarineInsurance, label: 'Marine Insurance' },
        { value: InsuranceTypes.SMEInsurance, label: 'SME Insurance' },
        { value: InsuranceTypes.LiabilityInsurance, label: 'Liability Insurance' },
        { value: InsuranceTypes.ProfessionalIndemnityInsurance, label: 'Professional Indemnity Insurance' },
        { value: InsuranceTypes.TravelInsurance, label: 'Travel Insurance' },
        { value: InsuranceTypes.HealthInsurance, label: 'Health Insurance' },
        { value: InsuranceTypes.AccidentalDamageInsurance, label: 'Accidental Damage Insurance' }
    ];
}