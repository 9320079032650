import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { AsideDefault } from './components/aside/AsideDefault'
import { useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { MenuItemModel } from './components/aside/models/MenuItemModel'

const  AnonymousMasterLayout = () => {

  useEffect(() => {

  }, [])

  return (
    <div className='page d-flex flex-row flex-column-fluid'>
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper' style={{paddingLeft: "0", paddingTop: "0"}}>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <Content>
              <Outlet />
            </Content>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export { AnonymousMasterLayout }
