import React, { FC } from "react";
import clsx from 'clsx'
import { Button, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { OptionModel } from "../Models/OptionModel";
import _ from "lodash";
import Select from "react-select";

interface FormGroupSelectProps {
    label: string;
    controlId: string;
    className?: string;
    name: string;
    onChange: (a: any, b: any) => void;
    onBlur: (a: any, b: boolean) => void;
    value: any;
    isInvalidError: string | undefined;
    touched: boolean | undefined;
    options: OptionModel[];
    required?: boolean;
}

const FormGroupSelect: React.FC<FormGroupSelectProps> = (props) => {
    let { label, controlId, className, value, name, isInvalidError, touched, required = true, onChange, onBlur, options, children } = props;

    const handleChange = value => {
        onChange(name, value.value);
    };

    const handleBlur = () => {
        onBlur(name, true);
    };

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        return inputValue;
    };

    return (
        <>
            <Form.Group className={className} controlId={controlId}>
                <Form.Label>{label} {required ? <span className="text-danger">*</span> : <></>}</Form.Label>
                <div className={clsx(`form-control-select`, (touched && !!isInvalidError) ? "is-invalid" : "")}>
                    <Select
                        options={options}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onInputChange={handleInputChange}
                        value={options.filter(function (option) {
                            return option.value === value;
                        })}
                    />
                </div>
                <div className="invalid-feedback">
                    {isInvalidError}
                </div>
            </Form.Group>
        </>
    );
}

export { FormGroupSelect }