/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as auditLogRedux from '../redux/AuditLogsRedux'
import { RootState } from '../../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { UserModel } from '../../../../@Common/Models/UserModel'
import { RoleModel } from '../../../../@Common/Models/RoleModel'
import { Cards } from '../../../../@Common/UIComponents/Cards'
import { Button, Card, CardGroup, Col, Container, Nav, Row } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../../_metronic/layout/core'
import { generatePath, useNavigate } from 'react-router-dom'
import { Loader } from '../../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../../@Common/UIComponents/ErrorBoundary'
import { ConfirmationModal } from '../../../../@Common/UIComponents/ConfirmationModal'
import { Buttons } from '../../../../@Common/UIComponents/Buttons'
import { Icons } from '../../../../@Common/Helpers/Icons'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../../@Common/Helpers/Toastr'
import { AuthorizeAccess } from '../../../../@Common/UIComponents/AuthorizeAccess'
import { CustomColumnDef, Grid } from '../../../../@Common/UIComponents/Grid'
import { AuditLogRecordModel } from '../models/AuditLogRecordModel'
import { GridFilterDto } from '../../../../@Common/Models/GridModel'
import { PaginatedResult } from '../../../../@Common/Models/PagiantedResult'
import { getAuditLogs, getFilteredUsers } from '../redux/AuditLogsActions'
import { FormGroupAsyncSelect } from '../../../../@Common/FormComponents/FormGroupAsyncSelect'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { AuditLogFilterModel } from '../models/AuditLogFilterModel'
import { nameOf } from '../../../../@Common/Helpers/Utilities'
import { AsyncDropdown } from '../../../../@Common/FormComponents/AsyncDropdown'
import AuditLogDetailsModal from './AuditLogDetailsModal'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, DateRangePicker } from 'react-date-range'
import moment from 'moment'
import { ButtonSpinner } from '../../../../@Common/UIComponents/ButtonSpinner'

const mapState = (state: RootState) => ({ auditLogs: state.auditLogs, permissions: state.auth.userPermissions })
const connector = connect(mapState, auditLogRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuditLogs: FC<PropsFromRedux> = ({ auditLogs, permissions }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState<AuditLogFilterModel>();
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchClicked, setIsSearchCliked] = useState(false)
    const [calendarState, setCalendarState] = useState([
        {
            startDate: moment().subtract(1, 'months').toDate(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [firstCalendarClicked, setFirstCalendarClicked] = useState(false);

    let { loading } = auditLogs;

    const columns = React.useMemo<CustomColumnDef<AuditLogRecordModel>[]>(
        () => [
            {
                id: 'service',
                accessorKey: 'service',
                cell: info => info.getValue(),
                header: () => 'Service'
            },
            {
                id: 'actionedOn',
                accessorKey: 'actionedOnStr',
                cell: info => info.getValue(),
                header: () => 'Actioned On',
                enableColumnFilter: false
            },
            {
                id: 'auditType',
                accessorKey: 'auditType',
                cell: info => info.getValue(),
                header: () => 'Audit Type',
                enableColumnFilter: false
            },
            {
                id: 'action',
                accessorKey: 'action',
                cell: info => info.getValue(),
                header: () => 'Action'
            },
            {
                header: "View",
                cell: ({ cell }) => (
                    <>
                        <Buttons
                            variant="primary"
                            className="m-1 btn-icon"
                            onClick={() => { onShowDetails(cell.row.original!) }}
                            icon={Icons.Expand}
                            tooltip="View">
                        </Buttons>
                    </>
                )
            },
        ],
        []
    )

    const onShowDetails = (auditLog: AuditLogRecordModel) => {
        dispatch(auditLogRedux.actions.showDetailsModal(auditLog.id));
    }

    const getData = async (options: GridFilterDto) => {
        var dataResponse = await getAuditLogs(options, searchData!);

        if (dataResponse.isSuccess)
            return dataResponse.data!;
        else
            return null;
    }

    const topPageFilters = () => {
        return (
            <>
                <Cards
                    border='dark'
                    header={<h2>Search Audit</h2>}
                >
                    <>
                        <Container fluid>
                            <Row>
                                <Col sm={1}>
                                    User
                                </Col>
                                <Col sm={10}>
                                    <AsyncDropdown
                                        className="mb-3"
                                        name={nameOf<AuditLogFilterModel>("userId")}
                                        onBlur={(a, b) => { }}
                                        onChange={(a, userId) => {
                                            setSelectedUserId(userId);
                                        }}
                                        getApiMethod={(inputValue: string) => getFilteredUsers(inputValue)}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={1}>
                                    Dates
                                </Col>
                                <Col sm={11}>
                                    <DateRangePicker
                                        ranges={calendarState}
                                        onChange={(item) => {
                                            setCalendarState([item.selection as any])
                                        }}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        direction="horizontal"

                                    />
                                </Col>
                            </Row>
                            <Row>
                                <ButtonSpinner
                                    onClick={() => { searchAudit() }}
                                    variant="primary"
                                    text='Search'
                                    saving={isSearching}
                                    className='m-1'
                                    disabled={!selectedUserId}
                                />
                            </Row>
                        </Container>
                    </>
                </Cards>
            </>
        );
    }

    const searchAudit = () => {
        if (selectedUserId && selectedUserId != '') {
            setIsSearching(true)
            setIsSearchCliked(true);
            setSearchData({ userId: selectedUserId, startDateStr: moment(calendarState[0].startDate).format("DD/MM/YYYY"), endDateStr: moment(calendarState[0].endDate).format("DD/MM/YYYY") } as AuditLogFilterModel)
            setTimeout(async () => {
                setIsSearchCliked(false);
            }, 100);
        }
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle>Audit Logs</PageTitle>
                <PageButtons></PageButtons>
                {topPageFilters()}
                <Loader loading={isSearchClicked} showTextLoading={true} customText={" "}>
                    {searchData &&
                        <>
                            <Grid<AuditLogRecordModel>
                                columns={columns}
                                getData={(options) => getData(options)}
                                onLoaded={() => {
                                    setIsSearching(false)
                                }}
                                maxPageSize={10} />
                        </>
                    }
                </Loader>
                <AuditLogDetailsModal />
            </ErrorBoundary >
        </>
    );
}

export default connector(AuditLogs)