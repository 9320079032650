import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../../@Common/Interfaces/ServiceResponse";
import { UserModel } from "../../../../@Common/Models/UserModel";
import { UserProfileSecurity } from "../models/UserProfileSecurity";
import { getUserProfile, getUserProfileSecurity } from "./ProfileActions";

export const actionTypes = {
    UserProfileRequested: '[Request User Profile] Action',
    UserProfileLoaded: '[Loaded User Profile] API',
    UserProfileFailed: '[Failed User Profile] API',
    UserUpdateSaved: '[User Update Saved] Action',
    UserDeleted: '[User Deleted] Action',
    UserProfileSecurityRequested: '[Request User Profile Security] Action',
    UserProfileSecurityLoaded: '[Loaded User Profile Security] API',
    UserPasswordSaved: '[User Password Saved] Action',
    User2FAEnabled: '[User 2FA Enabled] Action'
}

export interface IProfileState {
    user?: UserModel,
    loading: boolean,
    profileNotFound: boolean,
    userProfileSecurity?: UserProfileSecurity,
    loadingSecurity: boolean
}

const initialProfileState: IProfileState = {
    user: undefined,
    loading: false,
    profileNotFound: false,
    userProfileSecurity: undefined,
    loadingSecurity: false
}

export const reducer = (state: IProfileState = initialProfileState, action: ActionWithPayload<IProfileState>): IProfileState => {
    switch (action.type) {
        case actionTypes.UserProfileRequested: {
            return { ...state, loading: true };
        }

        case actionTypes.UserProfileLoaded: {
            return {
                ...state,
                user: action.payload?.user,
                loading: false,
                profileNotFound: false
            };
        }

        case actionTypes.UserProfileFailed: {
            return {
                ...state,
                loading: false,
                profileNotFound: true
            };
        }

        case actionTypes.UserProfileFailed: {
            return {
                ...state,
                loading: false,
                profileNotFound: true
            };
        }

        case actionTypes.UserProfileSecurityRequested: {
            return { ...state, loadingSecurity: true };
        }

        case actionTypes.UserProfileSecurityLoaded: {
            return {
                ...state,
                userProfileSecurity: action.payload?.userProfileSecurity,
                loadingSecurity: false
            };
        }

        case actionTypes.User2FAEnabled: {
            return {
                ...state,
                userProfileSecurity: {
                    ...state.userProfileSecurity!,
                    hasTwoFactorEnabled: true
                }
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestUserProfile: (userId: string) => ({ type: actionTypes.UserProfileRequested, payload: { userId } }),
    fulfillUserProfile: (user: UserModel) => ({ type: actionTypes.UserProfileLoaded, payload: { user } }),
    fulfillFailedUserProfile: () => ({ type: actionTypes.UserProfileFailed }),
    fulfillUpdateUserSaved: (userId: string) => ({ type: actionTypes.UserUpdateSaved, payload: { userId } }),
    requestUserProfileSecurity: (userId: string) => ({ type: actionTypes.UserProfileSecurityRequested, payload: { userId } }),
    fulfillUserProfileSecurity: (userProfileSecurity: UserProfileSecurity) => ({ type: actionTypes.UserProfileSecurityLoaded, payload: { userProfileSecurity } }),
    fulfillUserPasswordSaved: (userId: string) => ({ type: actionTypes.UserPasswordSaved, payload: { userId } }),
    fulfillUser2FAENabled: () => ({ type: actionTypes.User2FAEnabled, payload: {} })
}

export function* saga() {
    function* userProfileRequested(action: any) {
        let userId: string = action.payload.userId;
        const userResponse: ServiceResponse<UserModel> = yield getUserProfile(userId);

        if (userResponse.isSuccess)
            yield put(actions.fulfillUserProfile(userResponse.data!));
        else
            yield put(actions.fulfillFailedUserProfile());
    }

    yield takeLatest(actionTypes.UserProfileRequested, userProfileRequested);
    yield takeLatest(actionTypes.UserUpdateSaved, userProfileRequested);
    yield takeLatest(actionTypes.UserDeleted, userProfileRequested);

    function* userProfileSecurityRequested(action: any) {
        let userId: string = action.payload.userId;
        const response: ServiceResponse<UserProfileSecurity> = yield getUserProfileSecurity(userId);

        if (response.isSuccess)
            yield put(actions.fulfillUserProfileSecurity(response.data!));
    }

    yield takeLatest(actionTypes.UserProfileSecurityRequested, userProfileSecurityRequested);
    yield takeLatest(actionTypes.UserPasswordSaved, userProfileSecurityRequested);
}