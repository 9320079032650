import { put, takeLatest } from "redux-saga/effects";
import { showToastrError, showToastrErrors } from "../../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../../@Common/Interfaces/ActionWithPayload"
import { ServiceResponse } from "../../../../@Common/Interfaces/ServiceResponse";
import { PaymentBasicDetailsModel } from "../models/PaymentBasicDetailsModel";
import { getPaymentBasicDetails, getPaymentGateway } from "./OnlinePaymentActions";

export const actionTypes = {
    PaymentGatewayRequested: '[Request Payment Gateway] Action',
    PaymentGatewayLoaded: '[Loaded Payment Gateway] API',
    PaymentRequested: '[Request Payment] Action',
    PaymentLoaded: '[Loaded Payment] API',
}

export interface IOnlinePaymentState {
    loading: boolean,
    paymentGwHtml?: string,
    payment?: PaymentBasicDetailsModel
}

const initialOnlinePaymentState: IOnlinePaymentState = {
    loading: false
}

export const reducer = (state: IOnlinePaymentState = initialOnlinePaymentState, action: ActionWithPayload<IOnlinePaymentState>): IOnlinePaymentState => {
    switch (action.type) {
        case actionTypes.PaymentGatewayRequested: {
            return { 
                ...state,
                loading: true
             };
        }

        case actionTypes.PaymentGatewayLoaded: {
            return { 
                ...state,
                loading: false,
                paymentGwHtml: action.payload?.paymentGwHtml
             };
        }

        case actionTypes.PaymentRequested: {
            return { 
                ...state,
                loading: true
             };
        }

        case actionTypes.PaymentLoaded: {
            return { 
                ...state,
                loading: false,
                payment: action.payload?.payment
             };
        }

        default:
            return state
    }
}

export const actions = {
    requestPaymentGateway: (invoiceId: string) => ({ type: actionTypes.PaymentGatewayRequested, payload: { invoiceId } }),
    fulfillPaymentGateway: (paymentGwHtml: string) => ({ type: actionTypes.PaymentGatewayLoaded, payload: { paymentGwHtml } }),
    requestPayment: (paymentId: string) => ({ type: actionTypes.PaymentRequested, payload: { paymentId } }),
    fulfillPayment: (payment: PaymentBasicDetailsModel) => ({ type: actionTypes.PaymentLoaded, payload: { payment } }),
}

export function* saga() {
    yield takeLatest(actionTypes.PaymentGatewayRequested, function* paymentGatewayRequested(action: any){
        let invoiceId: string = action.payload.invoiceId;
        const paymentGatewayResponse: ServiceResponse<string> = yield getPaymentGateway(invoiceId);

        if (paymentGatewayResponse.isSuccess) {
            yield put(actions.fulfillPaymentGateway(paymentGatewayResponse.data!));
        }else{
            showToastrErrors(paymentGatewayResponse.errors);
        }
    })

    yield takeLatest(actionTypes.PaymentRequested, function* paymentRequested(action: any){
        let paymentId: string = action.payload.paymentId;
        const paymentResponse: ServiceResponse<PaymentBasicDetailsModel> = yield getPaymentBasicDetails(paymentId);

        if (paymentResponse.isSuccess) {
            yield put(actions.fulfillPayment(paymentResponse.data!));
        }else{
            showToastrErrors(paymentResponse.errors);
        }
    })
}