/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as onlinePaymentRedux from '../redux/OnlinePaymentRedux'
import { RootState } from '../../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../../@Common/UIComponents/Cards'
import { Button, Card, CardGroup, Nav } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../../_metronic/layout/core'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { Loader } from '../../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../../@Common/UIComponents/ErrorBoundary'
import { Timer } from './Timer'
import { getIsInvoiceOpen } from '../redux/OnlinePaymentActions'
import { showToastrErrors } from '../../../../@Common/Helpers/Toastr'

const mapState = (state: RootState) => ({ onlinePayment: state.onlinePayment })
const connector = connect(mapState, onlinePaymentRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const OnlinePayment: FC<PropsFromRedux> = ({ onlinePayment }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [invoiceOpen, setInvoiceOpen] = useState(true);

    const invoiceId = searchParams.get("invoiceId");

    const sessionDurationsMins = 20;

    useEffect(() => {
        async function checkInvoiceOpen() {
            let response = await getIsInvoiceOpen(invoiceId!);

            if (response.isSuccess) {
                let isOpen = response.data!;
                setInvoiceOpen(isOpen);

                if (isOpen)
                    await getPaymentGateway();
            } else
                showToastrErrors(response.errors);
        }

        async function getPaymentGateway() {
            dispatch(onlinePaymentRedux.actions.requestPaymentGateway(invoiceId!))
        }

        if (invoiceId == null || invoiceId == '') {
            let path = generatePath("/error/500", {});
            return navigate(path);
        } else
            checkInvoiceOpen();
    }, [])

    const onSessionExpired = () => {
        alert("Session has expired!");
    }

    return (
        <>
            <h1>Online Payment</h1>

            <ErrorBoundary>
                <Loader loading={onlinePayment.loading}>
                    <>
                        {invoiceOpen && onlinePayment.paymentGwHtml &&
                            <>
                                <iframe
                                    style={{ height: "800px", width: "100%", border: "0px", padding: "0px", margin: "0px" }}
                                    srcDoc={onlinePayment.paymentGwHtml}
                                />

                                {sessionDurationsMins &&
                                    <>
                                        <Timer precedingText={"Payment session will expire in"}
                                            startingMins={sessionDurationsMins}
                                            onFinish={onSessionExpired} />
                                    </>
                                }
                            </>
                        }
                        {!invoiceOpen &&
                            <>
                                <p>The provided invoice is invalid or has already been processed</p>
                            </>
                        }
                    </>
                </Loader>
            </ErrorBoundary>
        </>
    );
}

export default connector(OnlinePayment)