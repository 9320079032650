import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { PaginatedResult } from '../../../@Common/Models/PagiantedResult';
import { SubscriptionDashboardRecordModel } from '../models/SubscriptionDashboardRecordModel';
import { SubscriptionDetailsReadDto } from '../models/SubscriptionDetailsReadDto';
import { SubscriptionDetailsWriteDto } from '../models/SubscriptionDetailsWriteDto';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/subscriptionDashboard";

export const GET_SUBSCRIPTIONS_URL = `${API_URL}/GetSubscriptions`;
export const GET_SUBSCRIPTION_DETAILS_URL = `${API_URL}/GetSubscriptionDetails`;
export const CANCEL_SUBSCRIPTION_URL = `${API_URL}/CancelSubscription`;
export const SEND_INSTALLMENT_MANUAL_PAYMENT_EMAIL_URL = `${API_URL}/SendInstallmentManualPaymentEmail`;
export const EDIT_SUBSCRIPTION_DETAILS_URL = `${API_URL}/EditSubscriptionDetails`;

export const getSubscriptions = async (filter: GridFilterDto) => {
  let response = await postToServerAPI<PaginatedServiceResponse<SubscriptionDashboardRecordModel>>(GET_SUBSCRIPTIONS_URL, filter);
  return response;
}

export const getSubscriptionDetails = async (id: number) => {
  let response = await getFromServerAPI<ServiceResponse<SubscriptionDetailsReadDto>>(GET_SUBSCRIPTION_DETAILS_URL, { id });
  return response;
}

export const cancelSubscription = async (subscriptionId: number) => {
  let response = await postToServerAPI<ServiceResponse>(CANCEL_SUBSCRIPTION_URL, { subscriptionId });
  return response;
}

export const sendSubscriptionInstallmentManualEmail = async (subscriptionId: number, installmentId: number) => {
  let response = await postToServerAPI<ServiceResponse>(SEND_INSTALLMENT_MANUAL_PAYMENT_EMAIL_URL, { subscriptionId, installmentId });
  return response;
}

export const editSubscriptionDetails = async (model: SubscriptionDetailsWriteDto) => {
  let response = await postToServerAPI<ServiceResponse>(EDIT_SUBSCRIPTION_DETAILS_URL, model);
  return response;
}