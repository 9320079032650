import React from "react"
import { FC, useState } from "react"
import { CardGroup } from "react-bootstrap"
import { useIntl } from "react-intl"
import { shallowEqual, useSelector } from 'react-redux'
import { generatePath, Link, useNavigate } from "react-router-dom"
import { Icons } from "../../../@Common/Helpers/Icons"
import { Cards } from "../../../@Common/UIComponents/Cards"
import { RootState } from "../../../setup"
import { MenuItemModel } from "../../../_metronic/layout/components/aside/models/MenuItemModel"
import { PageTitle } from "../../../_metronic/layout/core"
import { Modals } from "../../../@Common/UIComponents/Modals"
import { getVersionHistory } from "./redux/HomeActions"
import { VersionHistory } from "./models/VersionHistory"
import { Loader } from "../../../@Common/UIComponents/Loader"
import { showToastrError } from "../../../@Common/Helpers/Toastr"

const HomeWrapper: FC = () => {
  const intl = useIntl()
  const menuItems: MenuItemModel[] = useSelector<RootState>(({ menu }) => menu.menuItems, shallowEqual) as MenuItemModel[]
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [versionHistory, setVersionHistory] = useState<VersionHistory[]>([])

  const displayMenuItems = () =>
    <>
      {menuItems && menuItems.filter(mi => mi.location).map((menuItem, i) =>
        <React.Fragment key={i}>
          <div className={`col-12`}>
            <Cards
              border='dark'
              title={
                <>
                  <div className='row'>
                    <div className='col-10'>
                      <i className={menuItem.iconClass}></i>
                      <span className={"ms-1"}>
                        <Link to={"/" + menuItem.location}>
                          {menuItem.name}
                        </Link>
                      </span>
                    </div>
                  </div>
                </>
              }
              key={"menu-" + i}
              className={"m-1"}
            >
              <div className="row">
                <p>{menuItem.parentMenuItem ? menuItem.parentMenuItem.name + " > " : ""}{menuItem.name}</p>
              </div>
            </Cards>
          </div>
        </React.Fragment>
      )}
    </>;

  const onVersionNumberClick = async () => {
    setShowModal(true)
    setLoading(true)
    let historyResponse = await getVersionHistory();

    if (historyResponse.isSuccess)
      setVersionHistory(historyResponse.data!);
    else
      showToastrError("There was a problem while retrieving version history");

    setLoading(false)
  }

  const versionNumber = () => <>v{process.env.REACT_APP_ICON_CORE_VERSION} <span style={{ cursor: "pointer" }} className={Icons.InfoCircle} onClick={onVersionNumberClick}></span></>;

  const displayVersionHistoryModal = () =>
    <>
      <Modals
        show={showModal}
        title={"Version History"}
        showSaving={false}
        onClose={() => setShowModal(false)}
      >
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Loader loading={loading}>
            {versionHistory && versionHistory.map((vh, vhi) =>
              <div key={vhi}>
                <h3>v{vh.versionNumber}</h3>
                <div dangerouslySetInnerHTML={{ __html: vh.changes }} />
              </div>
            )}
          </Loader>
        </div>
      </Modals>
    </>;

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'CORE.HOME' })}</PageTitle>
      <h2>Welcome! {versionNumber()}</h2>
      <div className="mt-4">
        <CardGroup>
          {displayMenuItems()}
        </CardGroup>
      </div>
      {displayVersionHistoryModal()}
    </>
  )
}

export { HomeWrapper }