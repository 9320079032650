import { createRef, useEffect, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Icons } from "../Helpers/Icons";
import { Buttons } from "./Buttons";
import { ButtonSpinner } from "./ButtonSpinner";

interface ConfirmationModalProps {
    show: boolean;
    title: string;
    question: JSX.Element;
    onConfirm: () => void;
    onClose: () => void;
    saving: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    const { show, title, question, onConfirm, onClose, saving, children } = props;
    let parentWrapper = document.getElementById("kt_wrapper");

    return (
        <>
            <Modal
                show={show}
                size="lg"
                onHide={onClose}
                container={parentWrapper}
                backdrop={"static"}
                className={"confirm-modal"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{question}</div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonSpinner
                        onClick={onConfirm}
                        text="Yes"
                        variant="primary"
                        saving={saving}
                        icon={Icons.Check}
                    />
                    <Buttons variant="danger" onClick={onClose} disabled={saving} icon={Icons.Times}>No</Buttons>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { ConfirmationModal }
