/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { changePassword, login, verifyResetPasswordToken } from '../redux/AuthActions'
import { LoginStatuses } from '../enums/LoginStatuses'
import { UserChangePasswordModel } from '../models/UserChangePasswordModel'

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required'),
  confirmNewPassword: Yup.string()
    .required('Confirm New Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
})

const initialValues = {
  newPassword: '',
  confirmNewPassword: ''
}

export function ResetPassword() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState<string>()
  const dispatch = useDispatch()
  const [invalidToken, setInvalidToken] = useState(false)
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false)

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    const token = urlParams.get('token');

    if (!token)
      setInvalidToken(true);
    else {
      try {
        let verifyTokenResponse = await verifyResetPasswordToken(token);

        if (verifyTokenResponse.isSuccess) {
          if (verifyTokenResponse.data)
            setToken(token);
          else
            setInvalidToken(true);
        } else
          formik.setStatus(verifyTokenResponse.status);
      } catch (error) {
        formik.setStatus("A problem was encountered while processing your request. Please try again later.");
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setStatus(null);

          let model: UserChangePasswordModel = {
            token: token!,
            newPassword: values.newPassword
          }

          let changePasswordResponse = await changePassword(model);

          if (!changePasswordResponse.isSuccess)
            setStatus(changePasswordResponse.errorsDisplay);
          else {
            formik.resetForm();
            setChangePasswordSuccess(true);
          }
        } catch (e) {
          setStatus("A problem was encountered while processing your request. Please try again later.");
        }
        setSubmitting(false);
        setLoading(false);
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
      </div>
      {/* begin::Heading */}

      {invalidToken ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>The provided token is either invalid or has expired. Kindly re-initiate the forgot password functionality</div>
        </div>
      ) : (
        <>
        </>
      )}

      {formik.status &&
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      }

      {changePasswordSuccess &&
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Your password has been successfully changed. Kindly log in with your new password.</div>
        </div>
      }

      {(!invalidToken && !changePasswordSuccess) &&
        <>
          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
                {/* end::Label */}
              </div>
            </div>
            <input
              placeholder='New Password'
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                },
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm New Password</label>
                {/* end::Label */}
              </div>
            </div>
            <input
              placeholder='Confirm New Password'
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('confirmNewPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.confirmNewPassword && formik.errors.confirmNewPassword,
                },
                {
                  'is-valid': formik.touched.confirmNewPassword && !formik.errors.confirmNewPassword,
                }
              )}
            />
            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmNewPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Change  Password</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </>
      }
      <div className='text-center'>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Back to Login
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}