import clsx from 'clsx'
import { Card } from "react-bootstrap";

interface CardProps {
    title?: JSX.Element;
    header?: JSX.Element;
    footer?: JSX.Element;
    border?: "dark" | "light" | "primary" | "info" | "danger";
    className?: string;
    bg?: "danger"
    text?: "dark" | "light" | "white"
}

const Cards: React.FC<CardProps> = (props) => {
    return (
        <>
            <Card
                className={clsx(`card-xl-stretch mb-xl-8`,  props.className)}
                border={props.border}
                bg={props.bg}
                text={props.text}
            >
                {props.header &&
                    <Card.Header>
                        {props.header}
                    </Card.Header>
                }
                <Card.Body>
                    {props.title &&
                        <Card.Title>{props.title}</Card.Title>
                    }
                    {props.children}
                </Card.Body>
                {props.footer &&
                    <Card.Footer>
                        {props.footer}
                    </Card.Footer>
                }
            </Card>
        </>
    );
}

export { Cards }
