import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { PaginatedResult } from '../../../@Common/Models/PagiantedResult';
import { PendingSubscriptionClientConfirmWriteModel } from '../models/PendingSubscriptionClientConfirmWriteModel';
import { PendingSubscriptionClientReadModel } from '../models/PendingSubscriptionClientReadModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/subscriptionRenewal";

const GET_CLIENT_RENEWAL_DETAILS_URL = API_URL + "/GetClientRenewalDetails";
const SAVE_CLIENT_RENEWAL_URL = API_URL + "/SaveClientRenewal";
const PAGE_ACCESSED_URL = API_URL + "/PageAccessed";

export const getClientRenewalDetails = async (renewalId: string) => {
    let response = await getFromServerAPI<ServiceResponse<PendingSubscriptionClientReadModel>>(GET_CLIENT_RENEWAL_DETAILS_URL, { renewalId });
    return response;
}

export const saveClientRenewal = async (model: PendingSubscriptionClientConfirmWriteModel) => {
    let response = await postToServerAPI<ServiceResponse>(SAVE_CLIENT_RENEWAL_URL, model);
    return response;
}

export const pageAccessed = async (renewalId: string) => {
    let response = await postToServerAPI<ServiceResponse>(PAGE_ACCESSED_URL, { renewalId });
    return response;
}