import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { PendingSubscriptionDashboardReadModel } from "../models/PendingSubscriptionDashboardReadModel";
import { getPendingSubscriptionDetails } from "./SubscriptionRenewalDashboardActions";

export const actionTypes = {
    ShowEditModal: '[Details Edit Modal Show] Action',
    HideEditModal: '[Details Edit Modal Hide] Action',
    DetailsRequested: '[Request Details] Action',
    DetailsLoaded: '[Request Details] API',
    DetailsUpdated: '[Details Updated] Action',
    RefreshTableRequested: '[Request Refresh Table] Action',
    RefreshTableUpdated: '[Updated Refresh Table] Action'
}

export interface ISubscriptionRenewalDashboardState {
    loading: boolean,
    showEditModal: boolean,
    currentPendingSubscriptionId?: number,
    loadingDetails: boolean,
    details?: PendingSubscriptionDashboardReadModel,
    refreshTable?: boolean
}

const initialSubscriptionDashboardState: ISubscriptionRenewalDashboardState = {
    loading: false,
    showEditModal: false,
    loadingDetails: false
}

export const reducer = (state: ISubscriptionRenewalDashboardState = initialSubscriptionDashboardState, action: ActionWithPayload<ISubscriptionRenewalDashboardState>): ISubscriptionRenewalDashboardState => {
    switch (action.type) {

        case actionTypes.ShowEditModal: {
            let currentPendingSubscriptionId = action.payload?.currentPendingSubscriptionId;
            return { ...state, showEditModal: true, currentPendingSubscriptionId };
        }

        case actionTypes.HideEditModal: {
            return {
                ...state,
                showEditModal: false,
                currentPendingSubscriptionId: undefined
            };
        }

        case actionTypes.DetailsRequested: {
            return { ...state, loadingDetails: true };
        }

        case actionTypes.DetailsLoaded: {
            return {
                ...state,
                loadingDetails: false,
                details: action.payload?.details!
            };
        }

        case actionTypes.RefreshTableRequested: {
            return { ...state, refreshTable: true };
        }

        case actionTypes.RefreshTableUpdated: {
            return { ...state, refreshTable: false };
        }

        default:
            return state
    }
}

export const actions = {
    showEditModal: (pendingSubscription: PendingSubscriptionDashboardReadModel) => ({ type: actionTypes.ShowEditModal, payload: { currentPendingSubscriptionId: pendingSubscription.id } }),
    hideDetailsModal: () => ({ type: actionTypes.HideEditModal, payload: {} }),
    requestSubscriptionRenewalDetails: (id: number) => ({ type: actionTypes.DetailsRequested, payload: { id } }),
    fulfillSubscriptionRenewalDetails: (pendingSubscription: PendingSubscriptionDashboardReadModel) => ({ type: actionTypes.DetailsLoaded, payload: { details: pendingSubscription } }),
    detailsUpdated: () => ({ type: actionTypes.DetailsUpdated, payload: {} }),
    refreshTable: () => ({ type: actionTypes.RefreshTableRequested, payload: {} }),
    refreshTableUpdated: () => ({ type: actionTypes.RefreshTableUpdated, payload: {} }),
}

export function* saga() {
    yield takeLatest(actionTypes.DetailsRequested, function* detailsRequested(action: any) {
        let id: number = action.payload.id;
        const response: ServiceResponse<PendingSubscriptionDashboardReadModel> = yield getPendingSubscriptionDetails(id);

        if (response.isSuccess) {
            yield put(actions.fulfillSubscriptionRenewalDetails(response.data!));
        } else {
            showToastrErrors(response.errors);
        }
    })

    yield takeLatest(actionTypes.RefreshTableRequested, function* requested(action: any) {
        yield put(actions.refreshTableUpdated());
    })
}