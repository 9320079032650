import clsx from 'clsx'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useLayout } from '../../../core/LayoutProvider'
import { usePageData } from '../../../core/PageData'

const DefaultPageButtons: FC = () => {
    const { pageButtons } = usePageData()
    return (
        <>
            {pageButtons &&
                <>
                    {pageButtons}
                </>
            }
        </>
    )
}

export { DefaultPageButtons }
