/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as onlinePaymentRedux from '../redux/OnlinePaymentRedux'
import { RootState } from '../../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../../@Common/UIComponents/Cards'
import { Button, Card, CardGroup, Nav } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../../_metronic/layout/core'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { Loader } from '../../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../../@Common/UIComponents/ErrorBoundary'
import { Timer } from './Timer'
import { getIsInvoiceOpen, getPaymentBasicDetails, processTransactionResponse } from '../redux/OnlinePaymentActions'
import { showToastrErrors } from '../../../../@Common/Helpers/Toastr'
import { PaymentStatus } from '../models/PaymentStatus'

const mapState = (state: RootState) => ({ onlinePayment: state.onlinePayment })
const connector = connect(mapState, onlinePaymentRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const PaymentConfirmation: FC<PropsFromRedux> = ({ onlinePayment }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { payment, loading } = onlinePayment;

    const paymentId = searchParams.get("paymentId");

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        async function processResponse() {
            dispatch(onlinePaymentRedux.actions.requestPayment(paymentId!))
        }

        processResponse();
    }, [])

    useEffect(() => {
        getDetails();
    }, [payment])

    const getDetails = () => {
        if (payment) {
            debugger;
            switch (payment.paymentStatus) {
                case PaymentStatus.Paid:
                    setTitle("Payment Paid");
                    setDescription(`Payment of Eur ${payment.amount} recieved successfully`);
                    break;
                case PaymentStatus.Rejected:
                    setTitle("Payment Rejected");
                    setDescription(`Payment has been rejected`);
                    break;
                case PaymentStatus.Cancelled:
                    setTitle("Payment Cancelled");
                    setDescription(`Payment has been cancelled`);
                    break;
                case PaymentStatus.Failed:
                    setTitle("Payment Failed!");
                    setDescription(`Payment was not processed successfully`);
                    break;
                default:
                    setTitle("");
                    setDescription("");
            }
        }
    }

    return (
        <>
            <Loader loading={loading}>
                <>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </>
            </Loader>
        </>
    );
}

export default connector(PaymentConfirmation)