import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { PendingSubscriptionClientReadModel } from "../models/PendingSubscriptionClientReadModel";
import { getClientRenewalDetails } from "./SubscriptionRenewalClientActions";

export const actionTypes = {
    ClientRenewalDetailsRequested: '[Request Client Renewal Details] Action',
    ClientRenewalDetailsLoaded: '[Loaded Client Renewal Details] API',
    DisplayError: '[Display Error]'
}

export interface ISubscriptionRenewalClientState {
    loading: boolean,
    renewalDetails?: PendingSubscriptionClientReadModel,
    errorMessages?: string[]
}

const initialSubscriptionRenewalClientState: ISubscriptionRenewalClientState = {
    loading: false
}

export const reducer = (state: ISubscriptionRenewalClientState = initialSubscriptionRenewalClientState, action: ActionWithPayload<ISubscriptionRenewalClientState>): ISubscriptionRenewalClientState => {
    switch (action.type) {
       
        case actionTypes.ClientRenewalDetailsRequested: {
            return { 
                ...state,
                loading: true,
                errorMessages: undefined
             };
        }

        case actionTypes.ClientRenewalDetailsLoaded: {
            return { 
                ...state,
                loading: false,
                renewalDetails: action.payload?.renewalDetails
             };
        }

        case actionTypes.DisplayError: {
            return { 
                ...state,
                loading: false,
                errorMessages: action.payload?.errorMessages
             };
        }

        default:
            return state
    }
}

export const actions = {
    requestClientRenewalDetails: (renewalId: string) => ({ type: actionTypes.ClientRenewalDetailsRequested, payload: { renewalId } }),
    fulfillClientRenewalDetails: (data: PendingSubscriptionClientReadModel) => ({ type: actionTypes.ClientRenewalDetailsLoaded, payload: { renewalDetails: data } }),
    displayErrors: (errorMessages: string[]) => ({ type: actionTypes.DisplayError, payload: { errorMessages } }),
}

export function* saga() {
    yield takeLatest(actionTypes.ClientRenewalDetailsRequested, function* clientRenewalDetailsRequested(action: any){
        let renewalId: string = action.payload.renewalId;
        const paymentGatewayResponse: ServiceResponse<PendingSubscriptionClientReadModel> = yield getClientRenewalDetails(renewalId);

        if (paymentGatewayResponse.isSuccess) {
            yield put(actions.fulfillClientRenewalDetails(paymentGatewayResponse.data!));
        }else{
            yield put(actions.displayErrors(paymentGatewayResponse.errors));
        }
    })

}