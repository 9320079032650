/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getAvailableAuthTypes, login, login_Google } from '../../../redux/AuthActions'
import { LoginStatuses } from '../../../enums/LoginStatuses'
import { RootState } from '../../../../../../setup'
import * as authRedux from '../../../redux/AuthRedux'
import { AuthTypes } from '../../../enums/AuthTypes'
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { showToastrError } from '../../../../../../@Common/Helpers/Toastr'
import { Button, Col } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../../../@Common/UIComponents/ButtonSpinner'
import { Icons } from '../../../../../../@Common/Helpers/Icons'
import { getLoginResponseErrorMessage } from '../../../functions/loginFunctions'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const GoogleButton: FC<PropsFromRedux> = ({ auth }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  let { loggingIn } = auth;

  interface GoogleLoginResponse {
    access_token: string
  }

  const onSuccess = async (response: GoogleLoginResponse) => {
    let stopLoading = true;
    setLoading(true)
    dispatch(authRedux.actions.loggingInStarted())

    let loginResponse = await login_Google(response.access_token);

    if (!loginResponse.isSuccess) {
      showToastrError(loginResponse.errorsDisplay);
    } else {
      let data = loginResponse.data;

      let errorMessage = getLoginResponseErrorMessage(data?.loginStatus!);

      if (errorMessage)
        showToastrError(errorMessage);

      switch (data?.loginStatus) {
        case LoginStatuses.Success:
          dispatch(authRedux.actions.login(data?.accessToken!, data?.refreshToken!, data?.userId!));
          stopLoading = false;
          break;
      }
    }

    if (stopLoading) {
      setLoading(false);
      dispatch(authRedux.actions.loggingInFinished())
    }
  };

  const onError = () => {
    showToastrError("There was a problem to logging in with your google account. Please try again later.");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onError
  });

  return (
    <>
      <ButtonSpinner
        className='btn btn-lg btn-secondary w-100 mb-5'
        onClick={() => googleLogin()}
        variant="primary"
        saving={loading}
        disabled={loggingIn}
      >
        <i className={Icons.Google}></i> Continue with Google
      </ButtonSpinner>
    </>
  )
}

export default connector(GoogleButton);