export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  let firstPartCurrent = current.split('/')[1];
  let firstPartUrl = url.split('/')[1];

  if (firstPartCurrent === firstPartUrl) {
    return true
  }

  return false
}
