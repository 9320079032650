import axios from 'axios'
import moment from 'moment';
import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { UserModel } from '../../../../@Common/Models/UserModel'
import { UserLoginResponseModel, UserTokenModel } from '../models/UserLoginResponseModel';
import { UserChangePasswordModel } from '../models/UserChangePasswordModel';
import { UserExpiredPasswordModel } from '../models/UserExpiredPasswordModel';
import { AuthTypes } from '../enums/AuthTypes';
import { UserLoginFacebookModel } from '../models/UserLoginFacebookModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/user";

export const LOGIN_URL = `${API_URL}/Login`
export const LOGIN_GOOGLE_URL = `${API_URL}/LoginWithGoogle`
export const LOGIN_FB_URL = `${API_URL}/LoginWithFacebook`
export const LOGIN_2FA_URL = `${API_URL}/Login2FA`
export const GET_USER_BY_TOKEN = `${API_URL}/GetUserByToken`
export const GET_USER_PERMISSIONS = `${API_URL}/GetUserPermissions`
export const REVOKE_REFRESH_TOKEN_URL = `${API_URL}/RevokeRefreshToken`
export const RESET_PASSWORD_URL = `${API_URL}/ResetPassword`
export const VERIFY_RESET_PASSWORD_TOKEN_URL = `${API_URL}/VerifyResetPasswordToken`
export const CHANGE_PASSWORD_URL = `${API_URL}/ChangePassword`
export const CHANGE_EXPIRED_PASSWORD_URL = `${API_URL}/ChangeExpiredPassword`
export const GET_AVAILABLE_AUTH_TYPES_URL = `${API_URL}/GetAvailableAuthTypes`
export const GET_GOOGLE_CLIENT_ID_URL = `${API_URL}/GetGoogleClientId`
export const GET_FACEBOOK_APP_ID_URL = `${API_URL}/GetFacebookAppId`

export const login = async (username: string, password: string) => {
  let loginResponse = await postToServerAPI<ServiceResponse<UserLoginResponseModel>>(LOGIN_URL, {
    username,
    password,
  });

  return loginResponse;
}

export const login2fa = async (username: string, password: string, code: string, forRecoveryCode: boolean) => {
  let loginResponse = await postToServerAPI<ServiceResponse<UserLoginResponseModel>>(LOGIN_2FA_URL, { username, password, code, forRecoveryCode });
  return loginResponse;
}

export const login_Google = async (accessToken: string) => {
  let loginResponse = await postToServerAPI<ServiceResponse<UserLoginResponseModel>>(LOGIN_GOOGLE_URL, {
    accessToken
  });

  return loginResponse;
}

export const login_Facebook = async (model: UserLoginFacebookModel) => {
  let loginResponse = await postToServerAPI<ServiceResponse<UserLoginResponseModel>>(LOGIN_FB_URL, model);

  return loginResponse;
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post('', {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

export const getUserByToken = async () => {
  let response = await postToServerAPI<UserModel>(GET_USER_BY_TOKEN);
  return response;
}

export const getUserPermissions = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<string[]>>(GET_USER_PERMISSIONS, { userId });
  return response;
}

export const revokeRefreshToken = async (userId: string) => {
  await postToServerAPI<ServiceResponse>(REVOKE_REFRESH_TOKEN_URL, { userId });
}

export const resetPassword = async (email: string) => {
  let forgotPasswordResponse = await postToServerAPI<ServiceResponse>(RESET_PASSWORD_URL, {
    email
  });

  return forgotPasswordResponse;
}

export const verifyResetPasswordToken = async (token: string) => {
  let verifyResponse = await getFromServerAPI<ServiceResponse<boolean>>(VERIFY_RESET_PASSWORD_TOKEN_URL, { token });
  return verifyResponse;
}

export const changePassword = async (changePassword: UserChangePasswordModel) => {
  let verifyResponse = await postToServerAPI<ServiceResponse>(CHANGE_PASSWORD_URL, changePassword);
  return verifyResponse;
}

export const changeExpiredPassword = async (changePassword: UserExpiredPasswordModel) => {
  let response = await postToServerAPI<ServiceResponse>(CHANGE_EXPIRED_PASSWORD_URL, changePassword);
  return response;
}

export const getAvailableAuthTypes = async () => {
  let response = await getFromServerAPI<ServiceResponse<AuthTypes[]>>(GET_AVAILABLE_AUTH_TYPES_URL, {});
  return response;
}

export const getGoogleClientId = async () => {
  let response = await getFromServerAPI<ServiceResponse<string>>(GET_GOOGLE_CLIENT_ID_URL, {});
  return response;
}

export const getFacebookAppId = async () => {
  let response = await getFromServerAPI<ServiceResponse<string>>(GET_FACEBOOK_APP_ID_URL, {});
  return response;
}