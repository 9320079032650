import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

interface TimerProps {
    startingMins: number;
    onFinish: () => void;
    precedingText: string;
    stopTimer?: boolean;
}

const Timer: React.FC<TimerProps> = (props) => {
    let { startingMins, onFinish, precedingText, children, stopTimer } = props;

    const setTimeDisplay = (num: number) => {
        if (num <= 9) return "0" + num;
        else return num.toString();
    }

    const mins = startingMins;
    let secs = mins * 60;

    const [currMins, setCurrMins] = useState(mins);
    const [currSecs, setCurrSecs] = useState(0);
    const [myInterval, setMyInterval] = useState<NodeJS.Timer>();

    let interval;

    const decrement = () => {
        setCurrMins(Math.floor(secs / 60));
        setCurrSecs(secs % 60);

        secs--;

        if (secs > -1) {
            // Continue
        }
        else {
            clearInterval(interval);
            onFinish();
        }
    }

    useEffect(() => {
        setCurrMins(Math.floor(secs / 60));
        setCurrSecs(secs % 60);

        interval = setInterval(decrement, 1000);
        setMyInterval(interval);
    }, [startingMins]);

    useEffect(() => {
        if (stopTimer)
            clearInterval((myInterval as any));
    }, [stopTimer]);

    return (
        <>
            <i><span>{precedingText} {`${setTimeDisplay(currMins)}:${setTimeDisplay(currSecs)}`}</span></i>
        </>
    );
}

export { Timer }
