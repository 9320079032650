import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import AuditLogs from './components/AuditLogs'

import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

const queryClient = new QueryClient()

const AuditLogsPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path="/"
                element={
                    <>
                        <QueryClientProvider client={queryClient}>
                            <AuditLogs />
                        </QueryClientProvider>
                    </>
                }
            />
        </Route>
        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
)

export default AuditLogsPage
