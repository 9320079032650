/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getAvailableAuthTypes, getGoogleClientId, login, login_Google } from '../../redux/AuthActions'
import { LoginStatuses } from '../../enums/LoginStatuses'
import { RootState } from '../../../../../setup'
import * as authRedux from '../../redux/AuthRedux'
import { AuthTypes } from '../../enums/AuthTypes'
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { showToastrError } from '../../../../../@Common/Helpers/Toastr'
import { Button } from 'react-bootstrap'
import GoogleButton from './Google/GoogleButton'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const Google: FC<PropsFromRedux> = ({ auth }) => {
  const [loading, setLoading] = useState(false)
  const [clientId, setClientId] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    getClientId();
  }, []);

  const getClientId = async () => {
    try {
      let clientIdResponse = await getGoogleClientId();

      if (clientIdResponse.isSuccess)
        setClientId(clientIdResponse.data!);
      else
        showToastrError("A problem was encountered while setting up google login mechanisms. Please try again later.");
    } catch (error) {
      showToastrError("A problem was encountered while setting up google login mechanisms. Please try again later.");
    }
  }

  return (
    <>
      {clientId &&
        <GoogleOAuthProvider clientId={`${clientId}.apps.googleusercontent.com`}>
          <GoogleButton />
        </GoogleOAuthProvider>
      }
    </>
  )
}

export default connector(Google);