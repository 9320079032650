import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../../@Common/Models/GridModel';
import { OptionModel } from '../../../../@Common/Models/OptionModel';
import { AuditLogDetailsModel } from '../models/AuditLogDetailsModel';
import { AuditLogFilterModel } from '../models/AuditLogFilterModel';
import { AuditLogRecordModel } from '../models/AuditLogRecordModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/auditLogs";

export const GET_AUDIT_LOGS_URL = `${API_URL}/GetAuditLogs`;
export const GET_AUDIT_LOG_DETAILS_URL = `${API_URL}/GetAuditLogDetails`;
export const GET_FILTERED_USERS_URL = `${API_URL}/GetFilteredUsers`;

export const getAuditLogs = async (filter: GridFilterDto, searchData: AuditLogFilterModel) => {
  let response = await postToServerAPI<PaginatedServiceResponse<AuditLogRecordModel>>(GET_AUDIT_LOGS_URL, { filter, searchData });
  return response;
}

export const getFilteredUsers = async (inputValue: string) => {
  return await getFromServerAPI<OptionModel[]>(GET_FILTERED_USERS_URL, { inputValue });
}

export const getAuditLogDetails = async (auditId: number) => {
  let response = await getFromServerAPI<ServiceResponse<AuditLogDetailsModel>>(GET_AUDIT_LOG_DETAILS_URL, { auditId });
  return response;
}