export enum InsuranceTypes {
    HomeInsurance = 1,
    MotorInsurance = 2,
    MarineInsurance = 3,
    SMEInsurance = 4,
    LiabilityInsurance = 5,
    ProfessionalIndemnityInsurance = 6,
    TravelInsurance = 7,
    HealthInsurance = 8,
    AccidentalDamageInsurance = 9
}