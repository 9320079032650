import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { AnonymousMasterLayout } from '../../../_metronic/layout/AnonymousMasterLayout'
import OnlinePayment from './components/OnlinePayment'
import PaymentConfirmation from './components/PaymentConfirmation'
import ProcessTransactionResponse from './components/ProcessTransactionResponse'

const OnlinePaymentPage = () => (
    <Routes>
        <Route element={<AnonymousMasterLayout />}>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path="/"
                    element={
                        <>
                            <OnlinePayment />
                        </>
                    }
                />
                <Route
                    path="/ProcessTransactionResponse"
                    element={
                        <>
                            <ProcessTransactionResponse />
                        </>
                    }
                />
                <Route
                    path="/PaymentConfirmation"
                    element={
                        <>
                            <PaymentConfirmation />
                        </>
                    }
                />
            </Route>
            <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
    </Routes>
)

export default OnlinePaymentPage
