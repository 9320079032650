import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../../@Common/Interfaces/ActionWithPayload"
import { AuditLogDetailsModel } from "../models/AuditLogDetailsModel";
import { ServiceResponse } from "../../../../@Common/Interfaces/ServiceResponse";
import { showToastrErrors } from "../../../../@Common/Helpers/Toastr";
import { getAuditLogDetails } from "./AuditLogsActions";

export const actionTypes = {
    ShowDetailsModal: '[Details Modal Show] Action',
    HideDetailsModal: '[Details Modal Hide] Action',
    AuditLogDetailsRequested: '[Request Audit Log Details] Action',
    AuditLogDetailsLoaded: '[Request Audit Log Details] API',
}

export interface IAuditLogState {
    loading: boolean,
    showDetailsModal: boolean,
    currentRecordId?: number,
    loadingDetails: boolean,
    auditLogDetails?: AuditLogDetailsModel
}

const initialAuditLogState: IAuditLogState = {
    loading: false,
    showDetailsModal: false,
    loadingDetails: false
}

export const reducer = (state: IAuditLogState = initialAuditLogState, action: ActionWithPayload<IAuditLogState>): IAuditLogState => {
    switch (action.type) {
        case actionTypes.ShowDetailsModal: {
            let currentRecordId = action.payload?.currentRecordId;
            return { ...state, showDetailsModal: true, currentRecordId };
        }

        case actionTypes.HideDetailsModal: {
            return {
                ...state,
                showDetailsModal: false,
                currentRecordId: undefined
            };
        }

        case actionTypes.AuditLogDetailsRequested: {
            return { ...state, loadingDetails: true };
        }

        case actionTypes.AuditLogDetailsLoaded: {
            return {
                ...state,
                loadingDetails: false,
                auditLogDetails: action.payload?.auditLogDetails!
            };
        }


        default:
            return state
    }
}

export const actions = {
    showDetailsModal: (recordId: number) => ({ type: actionTypes.ShowDetailsModal, payload: { currentRecordId: recordId } }),
    hideDetailsModal: () => ({ type: actionTypes.HideDetailsModal, payload: {} }),
    requestAuditLogDetails: (id: number) => ({ type: actionTypes.AuditLogDetailsRequested, payload: { id } }),
    fulfillAuditLogDetails: (auditDetails: AuditLogDetailsModel) => ({ type: actionTypes.AuditLogDetailsLoaded, payload: { auditLogDetails: auditDetails } })
}

export function* saga() {
    yield takeLatest(actionTypes.AuditLogDetailsRequested, function* auditLogDetailsRequested(action: any) {
        let id: number = action.payload.id;
        const response: ServiceResponse<AuditLogDetailsModel> = yield getAuditLogDetails(id);

        if (response.isSuccess) {
            yield put(actions.fulfillAuditLogDetails(response.data!));
        } else {
            showToastrErrors(response.errors);
        }
    })
}