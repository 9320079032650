/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as onlinePaymentRedux from '../redux/OnlinePaymentRedux'
import { RootState } from '../../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../../@Common/UIComponents/Cards'
import { Button, Card, CardGroup, Nav } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../../_metronic/layout/core'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { Loader } from '../../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../../@Common/UIComponents/ErrorBoundary'
import { Timer } from './Timer'
import { getIsInvoiceOpen, processTransactionResponse } from '../redux/OnlinePaymentActions'
import { showToastrErrors } from '../../../../@Common/Helpers/Toastr'

const ProcessTransactionResponse: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const transactionResponse = searchParams.get("params");

    const redirectUser = (url: string) => {
        url = url.replace(/&amp;/g, '&');

        if (url !== '') {
            window.parent.parent.location.replace(url);
        } else {
            //window.location.reload();
        }
    }

    useEffect(() => {
        async function processResponse() {
            let response = await processTransactionResponse(transactionResponse!);

            if (response.isSuccess) {
                redirectUser(response.data!);
            } else
                showToastrErrors(response.errors);
        }

        processResponse();
    }, [])

    return (
        <>

        </>
    );
}

export default ProcessTransactionResponse;