import React, { FC } from "react";
import clsx from 'clsx'
import { Button, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { OptionModel } from "../Models/OptionModel";
import _ from "lodash";

interface AsyncDropdownProps {
    className?: string;
    name: string;
    onChange: (a: any, b: any) => void;
    onBlur: (a: any, b: boolean) => void;
    getApiMethod: (inputValue: string) => Promise<OptionModel[]>;
}

const AsyncDropdown: React.FC<AsyncDropdownProps> = (props) => {
    let { className, name, getApiMethod, onChange, onBlur, children } = props;

    const handleChange = value => {
        onChange(name, value.value);
    };

    const handleBlur = () => {
        onBlur(name, true);
    };

    const handleInputChange = (newValue: string) => {
        // const inputValue = newValue.replace(/\W/g, '');
        return newValue;
    };

    const search = async (inputValue: string) => {
        let data = await getApiMethod(inputValue);
        return data;
    };

    const _loadOptions = (query, callback) => {
        search(query)
            .then(resp => callback(resp));
    };

    var loadOptions = _.debounce(_loadOptions, 500);

    return (
        <>
            <AsyncSelect
                // cacheOptions
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={handleInputChange}
            />
        </>
    );
}

export { AsyncDropdown }