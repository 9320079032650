import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { PaymentBasicDetailsModel } from '../models/PaymentBasicDetailsModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/onlinePayment";

const GET_PAYMENT_GW_HTML_URL = API_URL + "/GetPaymentGatewayHTML";
const GET_IS_INVOICE_OPEN_URL = API_URL + "/GetIsInvoiceOpen";
const PROCESS_TRANSACTION_RESPONSE_URL = API_URL + "/ProcessTransactionResponse";
const GET_PAYMENT_BASIC_DETAILS_URL = API_URL + "/GetPaymentBasicDetails";

export const getIsInvoiceOpen = async (invoiceId: string) => {
    let response = await getFromServerAPI<ServiceResponse<boolean>>(GET_IS_INVOICE_OPEN_URL, { invoiceId });
    return response;
}

export const getPaymentGateway = async (invoiceId: string) => {
    let response = await getFromServerAPI<ServiceResponse<string>>(GET_PAYMENT_GW_HTML_URL, { invoiceId });
    return response;
}

export const processTransactionResponse = async (transactionResponse: string) => {
    let response = await postToServerAPI<ServiceResponse<string>>(PROCESS_TRANSACTION_RESPONSE_URL, { transactionResponse });
    return response;
}

export const getPaymentBasicDetails = async (paymentId: string) => {
    let response = await getFromServerAPI<ServiceResponse<PaymentBasicDetailsModel>>(GET_PAYMENT_BASIC_DETAILS_URL, { paymentId });
    return response;
}