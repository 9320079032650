import { put, takeLatest } from "redux-saga/effects";
import { showToastrError, showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { RoleModel } from "../../../@Common/Models/RoleModel";
import { getUserMaxInstallments } from "./SubscriptionActions";

export const actionTypes = {
    UserMaxInstallmentsRequested: '[Request User Max Installments] Action',
    UserMaxInstallmentsLoaded: '[Loaded User Max Installments] API',
}

export interface ISubscriptionState {
    loading: boolean,
    userMaxInstallments: number
}

const initialSubscriptionState: ISubscriptionState = {
    loading: false,
    userMaxInstallments: 0
}

export const reducer = (state: ISubscriptionState = initialSubscriptionState, action: ActionWithPayload<ISubscriptionState>): ISubscriptionState => {
    switch (action.type) {
        case actionTypes.UserMaxInstallmentsRequested: {
            return { ...state };
        }

        case actionTypes.UserMaxInstallmentsLoaded: {
            return {
                ...state,
                userMaxInstallments: action.payload?.userMaxInstallments!
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestUserMaxSubscription: () => ({ type: actionTypes.UserMaxInstallmentsRequested }),
    fulfillUserMaxSubscription: (userMaxInstallments: number) => ({ type: actionTypes.UserMaxInstallmentsLoaded, payload: { userMaxInstallments } })
}

export function* saga() {
    yield takeLatest(actionTypes.UserMaxInstallmentsRequested, function* maxUserInstallmentsRequested() {
        const maxInstallmentsResponse: ServiceResponse<number> = yield getUserMaxInstallments();

        if (maxInstallmentsResponse.isSuccess) {
            yield put(actions.fulfillUserMaxSubscription(maxInstallmentsResponse.data!));
        }else{
            showToastrErrors(maxInstallmentsResponse.errors);
        }
    })
}