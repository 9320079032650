import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { SubscriptionDashboardRecordModel } from "../models/SubscriptionDashboardRecordModel";
import { SubscriptionDetailsReadDto } from "../models/SubscriptionDetailsReadDto";
import { SubscriptionReadModel } from "../models/SubscriptionReadModel";
import { getSubscriptionDetails, getSubscriptions } from "./SubscriptionDashboardActions";

export const actionTypes = {
    ShowDetailsModal: '[Details Modal Show] Action',
    HideDetailsModal: '[Details Modal Hide] Action',
    SubscriptionDetailsRequested: '[Request Subscription Details] Action',
    SubscriptionDetailsLoaded: '[Request Subscription Details] API',
}

export interface ISubscriptionDashboardState {
    loading: boolean,
    showDetailsModal: boolean,
    currentSubscriptionId?: number
    loadingDetails: boolean,
    subscriptionDetails?: SubscriptionDetailsReadDto
}

const initialSubscriptionDashboardState: ISubscriptionDashboardState = {
    loading: false,
    showDetailsModal: false,
    loadingDetails: false
}

export const reducer = (state: ISubscriptionDashboardState = initialSubscriptionDashboardState, action: ActionWithPayload<ISubscriptionDashboardState>): ISubscriptionDashboardState => {
    switch (action.type) {
        case actionTypes.ShowDetailsModal: {
            let currentSubscriptionId = action.payload?.currentSubscriptionId;
            return { ...state, showDetailsModal: true, currentSubscriptionId };
        }

        case actionTypes.HideDetailsModal: {
            return {
                ...state,
                showDetailsModal: false,
                currentSubscriptionId: undefined,
                subscriptionDetails: undefined
            };
        }

        case actionTypes.SubscriptionDetailsRequested: {
            return { ...state, loadingDetails: true };
        }

        case actionTypes.SubscriptionDetailsLoaded: {
            return {
                ...state,
                loadingDetails: false,
                subscriptionDetails: action.payload?.subscriptionDetails!
            };
        }

        default:
            return state
    }
}

export const actions = {
    showDetailsModal: (subscription: SubscriptionDashboardRecordModel) => ({ type: actionTypes.ShowDetailsModal, payload: { currentSubscriptionId: subscription.id } }),
    hideDetailsModal: () => ({ type: actionTypes.HideDetailsModal, payload: {} }),
    requestSubscriptionDetails: (id: number) => ({ type: actionTypes.SubscriptionDetailsRequested, payload: { id } }),
    fulfillSubscriptionDetails: (subscription: SubscriptionDetailsReadDto) => ({ type: actionTypes.SubscriptionDetailsLoaded, payload: { subscriptionDetails: subscription } })
}

export function* saga() {
    yield takeLatest(actionTypes.SubscriptionDetailsRequested, function* subscriptionDetailsRequested(action: any) {
        let id: number = action.payload.id;
        const response: ServiceResponse<SubscriptionDetailsReadDto> = yield getSubscriptionDetails(id);

        if (response.isSuccess) {
            yield put(actions.fulfillSubscriptionDetails(response.data!));
        } else {
            showToastrErrors(response.errors);
        }
    })
}