import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { OptionModel } from '../../../../@Common/Models/OptionModel';
import { VersionHistory } from '../models/VersionHistory';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/tenant";

export const GET_VERSION_HISTORY_URL = `${API_URL}/GetVersionHistory`;

export const getVersionHistory = async () => {
  let response = await getFromServerAPI<ServiceResponse<VersionHistory[]>>(GET_VERSION_HISTORY_URL, {});
  return response;
}