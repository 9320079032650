/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getAvailableAuthTypes, login } from '../redux/AuthActions'
import { LoginStatuses } from '../enums/LoginStatuses'
import { RootState } from '../../../../setup'
import * as authRedux from '../redux/AuthRedux'
import { AuthTypes } from '../enums/AuthTypes'
import UP from './authTypes/UP'
import { showToastrError } from '../../../../@Common/Helpers/Toastr'
import Google from './authTypes/Google'
import { GoogleOAuthProvider } from '@react-oauth/google'
import FB from './authTypes/FB'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const Login: FC<PropsFromRedux> = ({ auth }) => {
  const [availableAuthTypes, setaAailableAuthTypes] = useState<AuthTypes[]>()

  useEffect(() => {
    getAuthTypes();
  }, []);

  const getAuthTypes = async () => {
    try {
      let authTypesResponse = await getAvailableAuthTypes();

      if (authTypesResponse.isSuccess)
        setaAailableAuthTypes(authTypesResponse.data);
      else
        showToastrError("A problem was encountered while loading available login mechanisms. Please try again later.");
    } catch (error) {
      showToastrError("A problem was encountered while loading available login mechanisms. Please try again later.");
    }
  }

  return (
    <>
      {availableAuthTypes &&
        <>
          {availableAuthTypes?.indexOf(AuthTypes.UP) != -1 &&
            <UP />
          }

          {availableAuthTypes?.indexOf(AuthTypes.Google) != -1 &&
            <Google />
          }

          {availableAuthTypes?.indexOf(AuthTypes.Facebook) != -1 &&
            <FB />
          }
        </>
      }
    </>
  )
}

export default connector(Login);