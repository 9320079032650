import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { UserModel } from '../../../../@Common/Models/UserModel';
import { ChangePasswordModel } from '../models/ChangePasswordModel';
import { VerifyUser2FAModel as VerifyUser2FAModel, RecoveryUser2FAResponseModel } from '../models/VerifyUser2FAModel';
import { UpdateUserModel } from '../models/UpdateUserModel';
import { RegisterUser2FA, UserProfileSecurity } from '../models/UserProfileSecurity';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/profile";

export const GET_USER_URL = `${API_URL}/GetUser`;
export const UPDATE_USER_URL = `${API_URL}/UpdateUser`;
export const DELETE_USER_URL = `${API_URL}/DeleteUser`;
export const DEACTIVATE_USER_URL = `${API_URL}/DeactivateUser`;
export const ACTIVATE_USER_URL = `${API_URL}/ActivateUser`;
export const UNLOCK_USER_URL = `${API_URL}/UnlockUser`;
export const CHANGE_USER_PASSWORD_URL = `${API_URL}/ChangeUserPassword`;
export const GET_USER_PROFILE_SECURITY_URL = `${API_URL}/GetUserProfileSecurity`;
export const GET_USER_INIT_2FA_URL = `${API_URL}/GetUserInit2FA`;
export const SAVE_USER_2FA_URL = `${API_URL}/SaveUser2FA`;
export const DISABLE_USER_2FA_URL = `${API_URL}/DisableUser2FA`;
export const RESET_USER_2FA_URL = `${API_URL}/ResetUser2FA`;
export const GENERATE_CODES_USER_2FA_URL = `${API_URL}/GenerateCodesUser2FA`;

export const getUserProfile = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<UserModel>>(GET_USER_URL, { userId });
  return response;
}

export const saveUpdateUser = async (user: UpdateUserModel) => {
  let response = await postToServerAPI<ServiceResponse>(UPDATE_USER_URL, user);
  return response;
}

export const deleteUser = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(DELETE_USER_URL, { userId });
  return response;
}

export const changeUserPassword = async (model: ChangePasswordModel) => {
  let response = await postToServerAPI<ServiceResponse>(CHANGE_USER_PASSWORD_URL, model);
  return response;
}

export const getUserProfileSecurity = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<UserProfileSecurity>>(GET_USER_PROFILE_SECURITY_URL, { userId });
  return response;
}

export const getUserInit2FA = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<RegisterUser2FA>>(GET_USER_INIT_2FA_URL, { userId });
  return response;
}

export const saveUser2FA = async (model: VerifyUser2FAModel) => {
  let response = await postToServerAPI<ServiceResponse<RecoveryUser2FAResponseModel>>(SAVE_USER_2FA_URL, model);
  return response;
}

export const disableUser2FA = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(DISABLE_USER_2FA_URL, { userId });
  return response;
}

export const resetUser2FA = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(RESET_USER_2FA_URL, { userId });
  return response;
}

export const generateCodesUser2FA = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse<RecoveryUser2FAResponseModel>>(GENERATE_CODES_USER_2FA_URL, { userId });
  return response;
}

export const unlockUser = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(UNLOCK_USER_URL, { userId });
  return response;
}

export const deactivateUser = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(DEACTIVATE_USER_URL, { userId });
  return response;
}

export const activateUser = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(ACTIVATE_USER_URL, { userId });
  return response;
}