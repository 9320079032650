/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as auditLogRedux from '../redux/AuditLogsRedux'
import { RootState } from '../../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { UserModel } from '../../../../@Common/Models/UserModel'
import { RoleModel } from '../../../../@Common/Models/RoleModel'
import { Cards } from '../../../../@Common/UIComponents/Cards'
import { Accordion, Button, Card, CardGroup, Col, Container, Nav, Row } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../../_metronic/layout/core'
import { generatePath, useNavigate } from 'react-router-dom'
import { Loader } from '../../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../../@Common/UIComponents/ErrorBoundary'
import { ConfirmationModal } from '../../../../@Common/UIComponents/ConfirmationModal'
import { Buttons } from '../../../../@Common/UIComponents/Buttons'
import { Icons } from '../../../../@Common/Helpers/Icons'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../../@Common/Helpers/Toastr'
import { AuthorizeAccess } from '../../../../@Common/UIComponents/AuthorizeAccess'
import { CustomColumnDef, Grid } from '../../../../@Common/UIComponents/Grid'
import { AuditLogRecordModel } from '../models/AuditLogRecordModel'
import { GridFilterDto } from '../../../../@Common/Models/GridModel'
import { PaginatedResult } from '../../../../@Common/Models/PagiantedResult'
import { getAuditLogs, getFilteredUsers } from '../redux/AuditLogsActions'
import { FormGroupAsyncSelect } from '../../../../@Common/FormComponents/FormGroupAsyncSelect'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { AuditLogFilterModel } from '../models/AuditLogFilterModel'
import { nameOf } from '../../../../@Common/Helpers/Utilities'
import { AsyncDropdown } from '../../../../@Common/FormComponents/AsyncDropdown'
import { Modals } from '../../../../@Common/UIComponents/Modals'
import { AuditTypes } from '../enums/AuditTypes'

const mapState = (state: RootState) => ({ auditLogs: state.auditLogs })
const connector = connect(mapState, auditLogRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuditLogDetailsModal: FC<PropsFromRedux> = ({ auditLogs }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { loading, showDetailsModal, currentRecordId, auditLogDetails, loadingDetails } = auditLogs;

    useEffect(() => {
        async function loadDetails() {
            dispatch(auditLogRedux.actions.requestAuditLogDetails(currentRecordId!))
        }

        if (showDetailsModal) {
            loadDetails();
        }
    }, [showDetailsModal])

    const onCloseDetailsModal = () => {
        dispatch(auditLogRedux.actions.hideDetailsModal());
    }

    return (
        <>
            <Modals
                show={showDetailsModal}
                title={"Audit Details"}
                onClose={onCloseDetailsModal}
                showSaving={false}
            >
                <>
                    {auditLogDetails &&
                        <>
                            <Loader loading={loadingDetails}>
                                <Container fluid>
                                    <Row>
                                        <Col sm={3}>
                                            <b>Audit Type</b>
                                        </Col>
                                        <Col>
                                            {auditLogDetails.auditTypeStr}
                                        </Col>
                                    </Row>
                                    {auditLogDetails.action &&
                                        <Row>
                                            <Col sm={3}>
                                                <b>Action</b>
                                            </Col>
                                            <Col>
                                                {auditLogDetails.action}
                                            </Col>
                                        </Row>
                                    }
                                    <br />
                                    {auditLogDetails.auditType != AuditTypes.Insert &&
                                        <Cards
                                            border='light'
                                            title={
                                                <>
                                                    {(auditLogDetails.auditType == AuditTypes.Update) &&
                                                        <h2>Same Values</h2>
                                                    }

                                                    {(auditLogDetails.auditType == AuditTypes.Delete || auditLogDetails.auditType == AuditTypes.Read) &&
                                                        <h2>Values</h2>
                                                    }
                                                </>
                                            }
                                        >
                                            <div style={{ maxHeight: "245px", overflow: "auto" }}>
                                                <ul>
                                                    {auditLogDetails.sameValues.map((v, vi) => <li key={vi}><b>{v.fieldName}</b> - {v.value}</li>)}
                                                </ul>
                                            </div>
                                        </Cards>
                                    }
                                    {(auditLogDetails.auditType == AuditTypes.Update || auditLogDetails.auditType == AuditTypes.Insert) &&
                                        <Cards
                                            border='light'
                                            title={
                                                <>
                                                    {(auditLogDetails.auditType == AuditTypes.Update) &&
                                                        <h2>Changed Values</h2>
                                                    }

                                                    {(auditLogDetails.auditType == AuditTypes.Insert) &&
                                                        <h2>Values</h2>
                                                    }
                                                </>
                                            }
                                        >
                                            <div style={{ maxHeight: "245px", overflow: "auto" }}>
                                                <ul>
                                                    {auditLogDetails.differentValues.map((v, vi) => <li key={vi}><b>{v.fieldName}</b> - {v.valueBefore ? v.valueBefore + " -> " : ""} {v.value}</li>)}
                                                </ul>
                                            </div>
                                        </Cards>
                                    }
                                </Container>
                            </Loader>
                        </>
                    }
                </>
            </Modals>
        </>
    );
}

export default connector(AuditLogDetailsModal)