import { useEffect, useState } from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { Icons } from "../Helpers/Icons";
import { Buttons } from "./Buttons";

interface ButtonSpinnerProps {
    saving: boolean;
    onClick?: () => void;
    variant: "info" | "primary" | "danger";
    text?: string;
    icon?: string;
    savingText?: string;
    className?: string;
    disabled?: boolean;
    tooltip?: string;
}

const ButtonSpinner: React.FC<ButtonSpinnerProps> = (props) => {
    const { saving, onClick, variant, text, icon, children, savingText, className, disabled, tooltip } = props;

    ButtonSpinner.defaultProps = {
        savingText: "Please Wait..."
    };

    return (
        <>
            {saving ?
                <Buttons className={className} variant={variant} disabled>
                    {savingText != '' && savingText}
                    <Spinner
                        className="ms-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </Buttons>
                :
                <Buttons className={className} variant={variant} onClick={onClick} icon={icon} disabled={disabled} tooltip={tooltip}>
                    {text}
                    {children}
                </Buttons>
            }
        </>
    );
}

export { ButtonSpinner }
