import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import { ActionWithPayload } from '../../../../@Common/Interfaces/ActionWithPayload';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { UserModel } from '../../../../@Common/Models/UserModel'
import { UserTokenModel } from '../models/UserLoginResponseModel';
import { getUserByToken, getUserPermissions, revokeRefreshToken } from "./AuthActions";

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UserPermissionsRequested: '[Request User Permissions] Action',
  UserPermissionLoaded: '[Load User Permission] API',
  TokenRefreshed: '[Refreshed Token] Action',
  LoginActionStarted: '[User Log In] Action Started',
  LoginActionFinished: '[User Log In] Action Finished',
}

export interface IAuthState {
  user?: UserModel
  accessToken?: UserTokenModel,
  refreshToken?: UserTokenModel,
  userPermissions?: string[],
  loggingIn: boolean
}

const initialAuthState: IAuthState = {
  user: undefined,
  userPermissions: [],
  loggingIn: false
}

export const reducer = persistReducer(
  { storage, key: 'icon-core-auth', whitelist: ['user', 'accessToken', 'refreshToken', 'userPermissions'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        const refreshToken = action.payload?.refreshToken;

        return { ...state, accessToken, refreshToken, user: undefined }
      }

      case actionTypes.TokenRefreshed: {
        const accessToken = action.payload?.accessToken;
        const refreshToken = action.payload?.refreshToken;

        return { ...state, accessToken, refreshToken }
      }
      // case actionTypes.Register: {
      //   const accessToken = action.payload?.accessToken
      //   return { accessToken, user: undefined }
      // }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.UserPermissionLoaded: {
        const userPermissions = action.payload?.userPermissions
        return { ...state, userPermissions }
      }

      case actionTypes.LoginActionStarted: {
        return { ...state, loggingIn: true }
      }

      case actionTypes.LoginActionFinished: {
        return { ...state, loggingIn: false }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: UserTokenModel, refreshToken: UserTokenModel, userId: string) => ({ type: actionTypes.Login, payload: { accessToken, refreshToken, userId } }),
  // register: (accessToken: string) => ({
  //   type: actionTypes.Register,
  //   payload: { accessToken },
  // }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  tokenRefreshed: (accessToken: UserTokenModel, refreshToken: UserTokenModel) => ({ type: actionTypes.TokenRefreshed, payload: { accessToken, refreshToken } }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  requestUserPermissions: (userId: string) => ({ type: actionTypes.UserPermissionsRequested, payload: { userId } }),
  fulfillUserPermissions: (userPermissions: string[]) => ({ type: actionTypes.UserPermissionLoaded, payload: { userPermissions } }),
  loggingInStarted: () => ({ type: actionTypes.LoginActionStarted, payload: { } }),
  loggingInFinished: () => ({ type: actionTypes.LoginActionFinished, payload: { } }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(action: any) {
    let userId: string = action.payload.userId;
    yield put(actions.requestUser());
    yield put(actions.requestUserPermissions(userId));
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const user = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  })

  yield takeLatest(actionTypes.UserPermissionsRequested, function* userPermissionRequested(action: any) {
    let userId: string = action.payload.userId;
    const userPermissions: ServiceResponse<string[]> = yield getUserPermissions(userId);

    if (userPermissions.isSuccess)
      yield put(actions.fulfillUserPermissions(userPermissions.data!));
  })
}